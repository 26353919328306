import CropFreeIcon from "@mui/icons-material/CropFree";
import {
  Alert,
  Autocomplete,
  Box,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FilterOptionsState } from "@mui/material/useAutocomplete";
import { nanoid } from "nanoid";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { scanditLicense } from "env";
import { filterPartList, intlFormatPrice } from "helpers";
import { StockLocationType } from "models/StockLocationType";
import { UsedPart } from "models/usedPart";
import { Maybe, PartStatus, PartType, StockStore } from "operations/schema/schema";
import { useAppDispatch, useAppSelector } from "store";
import { selectConnectionStatus } from "store/root.store";
import {
  addPart,
  selectSelectedJob,
  selectSelectedJobVisit,
  updatePart,
} from "store/slices/jobs.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";

import { AsolviSwitch } from "components/AsolviSwitch";
import DecreaseButton from "components/DecreaseButton";
import { FilterAppBar } from "components/FilterAppBar";
import FullScreenDialog from "components/FullScreenDialog";
import IncreaseButton from "components/IncreaseButton";
import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import StyledTextField from "components/StyledTextField";
import { BarcodeScannerDialog } from "components/barcodeScan";

import { isEqual } from "lodash";
import { AddPartFilter, PartFilterKeys, defaultPartFilter } from "models/AddPartFilter";
import { AddPartEnum } from "./AddPartEnum";
import { AddPartFilterDialog } from "./AddPartFilterDialog";

const PREFIX = "AddPart";

const classes = {
  flex: `${PREFIX}-flex`,
  toggleButtonGroup: `${PREFIX}-toggleButtonGroup`,
  toggleButton: `${PREFIX}-toggleButton`,
  buttonDisabled: `${PREFIX}-buttonDisabled`,
  radioButton: `${PREFIX}-radioButton`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2),

  [`& .${classes.flex}`]: {
    marginTop: theme.spacing(2),
    display: "flex",
  },

  [`& .${classes.toggleButtonGroup}`]: {
    width: "100%",
    height: "48px",
  },

  [`& .${classes.toggleButton}`]: {
    flex: 1,
    "&.Mui-selected": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.info.main,
      "&:hover": {
        backgroundColor: theme.palette.common.white,
      },
      border: `1px solid ${theme.palette.info.main} !important`,
    },
  },

  [`& .${classes.radioButton}`]: {
    "&.Mui-checked": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.info.main,
    },
  },

  [`& .${classes.buttonDisabled}`]: {
    border: `1px solid rgba(0, 0, 0, 0.26)`,
  },
}));

interface AddPartProps {
  setOpenAddPartDialog: (open: boolean) => void;
  loading: boolean;
  stockCheckboxes: StockLocationType[];
  stockLocation: StockLocationType;
  setStockLocation: (location: StockLocationType) => void;
  dialogType: AddPartEnum;
  showSuggestedParts: boolean;
  setShowSuggestedParts: Dispatch<SetStateAction<boolean>>;
  partSearch: string;
  setPartSearch: Dispatch<SetStateAction<string>>;
}

export const AddPart: FC<AddPartProps> = (props) => {
  const {
    partSearch,
    setPartSearch,
    loading,
    stockLocation,
    stockCheckboxes,
    setStockLocation,
    setOpenAddPartDialog,
    showSuggestedParts,
    setShowSuggestedParts,
  } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isOnline = useAppSelector(selectConnectionStatus);
  const job = useAppSelector(selectSelectedJob);
  const { usedParts } = useAppSelector(selectSelectedJobVisit);
  const { userVar: userData, engineerSettings } = useAppSelector((state) => state.user);
  const {
    availableParts,
    requestableParts,
    suggestedParts,
    loading: cacheLoading,
  } = useAppSelector((state) => state.cache);
  const hasBarcodeScanner = scanditLicense();

  const allowNegativeStock = engineerSettings?.allowNegativeStock ?? false;
  const allowSalesPrice = engineerSettings?.allowSalesPrice ?? false;

  const [selectedPart, setSelectedPart] = useState<PartType | null>(null);
  const [qty, setQty] = useState<number>(1);
  const [tempQty, setTempQty] = useState<string>("1");
  const [inputError, setInputError] = useState<string>("");
  const [totalUsedPart, setTotalUsedPart] = useState<number>(0);
  const [partList, setPartList] = useState<PartType[]>([]);
  const [openScanner, setOpenScanner] = useState(false);
  const [scannerValue, setScannerValue] = useState("");
  const [dialogType, setDialogType] = useState(props.dialogType);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);

  const [appliedFilter, setAppliedFilter] = useState<AddPartFilter>(defaultPartFilter);
  const [filter, setFilter] = useState<AddPartFilter>(defaultPartFilter);

  const filterActiveCount = () => {
    let count = 0;
    for (const k of PartFilterKeys) {
      if (isEqual(defaultPartFilter[k], appliedFilter[k])) continue;
      count++;
    }
    return count;
  };

  const isAddPart = dialogType === AddPartEnum.AddPart;
  const equipmentId = job?.equipment?.id ?? "";

  const changeSelectedPart = (selectedPart: PartType | null) => {
    setSelectedPart(selectedPart);
    if (selectedPart !== null) {
      const cachedPart = usedParts.filter(
        (p) =>
          p.part.id === selectedPart.id &&
          p.part.partNumber === selectedPart.partNumber &&
          p.part.stockId === selectedPart.stockId
      );
      setTotalUsedPart(cachedPart.reduce((acc, p) => acc + (p ? p.part.quantity || 0 : 0), 0));
    }

    setQty(1);
    setTempQty("1");
  };

  const changeQty = (amount: number) => {
    const value = qty + amount;
    setQty(value);
    setTempQty(value.toString());
  };

  const changeQtyFromInput = (value: string) => {
    setTempQty(value);
    if (selectedPart !== null) {
      const intValue = parseInt(value);
      var limit = selectedPart.quantity ?? 1000;
      if (
        (selectedPart.isNonStock && intValue > 0) ||
        allowNegativeStock ||
        (intValue > 0 && intValue <= (limit || 0) && intValue + totalUsedPart <= (limit || 0))
      ) {
        setQty(intValue);
        setInputError("");
      } else {
        if ((limit || 0) - totalUsedPart >= 1) {
          setInputError(
            intl.formatMessage(
              {
                id: "part.quantityBetween",
              },
              {
                qty: (limit || 0) - totalUsedPart,
              }
            )
          );
        }
      }
    }
  };

  const getStockId = (stockStore?: Maybe<StockStore>) => {
    switch (stockStore) {
      case StockStore.Engineer:
        return userData?.stockId;
      case StockStore.Nonstock:
        return stockLocation.locationId;
      case StockStore.Customer:
        return job.customer?.id;
      default:
        return "";
    }
  };

  const getStoreLabel = (part: PartType) => {
    if (part.stockStore === StockStore.Nonstock) {
      return `${StockStore.Nonstock} - ${stockLocation.type}`;
    }

    return part.stockStore;
  };

  const addPartCb = () => {
    if (!selectedPart) return;
    const part: PartType = {
      id: selectedPart.id || "",
      partNumber: selectedPart.partNumber || "",
      barcode: selectedPart ? selectedPart.barcode : "",
      quantity: qty + totalUsedPart,
      stockStore: selectedPart.stockStore || StockStore.Engineer,
      stockId: selectedPart.stockId ?? getStockId(selectedPart.stockStore),
      storeLabel: getStoreLabel(selectedPart!),
      isNonStock: selectedPart.isNonStock,
      description: selectedPart.description || "",
      status: isAddPart ? PartStatus.Used : PartStatus.Requested,
      salesPrice: selectedPart.salesPrice,
    };
    const usedPart: UsedPart = {
      part,
      maxQuantity: selectedPart.quantity!,
      salesPriceChanged: false,
    };

    const existingPart = usedParts.find((p) => {
      return (
        p.part?.id === selectedPart.id && p.part?.stockId === getStockId(selectedPart.stockStore)
      );
    });
    if (existingPart) {
      dispatch(updatePart({ part: usedPart }));
    } else {
      dispatch(addPart({ part: usedPart }));
    }

    setSelectedPart(null);
    dispatch(
      addSnackbarMessage({
        key: isAddPart ? "AddPart-success" : "RequestPart-success",
        argument: part.partNumber ?? "",
      })
    );
  };

  const closeDialog = () => {
    setOpenAddPartDialog(false);
  };

  const applyFilters = useCallback(
    (partList: PartType[]) => {
      let newPartList = partList;
      if (appliedFilter) {
        // Evatic Specific
        if (!!appliedFilter.selectedSuppliers?.length) {
          newPartList = newPartList.filter((p) =>
            appliedFilter.selectedSuppliers.map((s) => s.id).includes(p.supplierId!)
          );
        }
        if (!!appliedFilter.sortGroup1?.length) {
          newPartList = newPartList.filter(
            (p) => appliedFilter.sortGroup1.findIndex((x) => x.code === p.sortGroup1) !== -1
          );
        }
        if (!!appliedFilter.sortGroup2?.length) {
          newPartList = newPartList.filter(
            (p) => appliedFilter.sortGroup2.findIndex((x) => x.code === p.sortGroup2) !== -1
          );
        }
        if (!!appliedFilter.sortGroup3?.length) {
          newPartList = newPartList.filter(
            (p) => appliedFilter.sortGroup3.findIndex((x) => x.code === p.sortGroup3) !== -1
          );
        }
        if (!!appliedFilter.sortGroup4?.length) {
          newPartList = newPartList.filter(
            (p) => appliedFilter.sortGroup4.findIndex((x) => x.code === p.sortGroup4) !== -1
          );
        }
        if (!!appliedFilter.sortGroup5?.length) {
          newPartList = newPartList.filter(
            (p) => appliedFilter.sortGroup5.findIndex((x) => x.code === p.sortGroup5) !== -1
          );
        }
      }
      return newPartList;
    },
    [appliedFilter]
  );

  const setAvailableParts = useCallback(() => {
    if (isAddPart) {
      let newPartList: PartType[] = [];
      if (stockLocation.type === StockStore.Engineer) {
        newPartList = availableParts.engineerParts;
      } else if (stockLocation.type === StockStore.Customer) {
        if (job.customer?.id) {
          newPartList = availableParts.customerParts[job.customer.id] ?? [];
        }
      } else if (stockLocation.type === StockStore.Locational) {
        if (isOnline && partSearch) {
          newPartList = availableParts.liveSearchParts;
        } else {
          newPartList = availableParts.locationalParts;
        }
      }

      newPartList = newPartList.filter(
        (part: PartType) =>
          part.stockStore &&
          (stockLocation.type === part.stockStore ||
            (part.stockStore !== StockStore.Other && stockLocation.type !== part.stockStore))
      );

      if (showSuggestedParts) {
        newPartList = newPartList.filter(
          (p) => suggestedParts[equipmentId]?.findIndex((x) => x === p.id) !== -1
        );
      }

      if (appliedFilter) {
        newPartList = applyFilters(newPartList);
      }

      //sorting is needed for groupping to avoid duplicates
      newPartList.sort((a, b) => {
        let fa = a.stockName?.toLowerCase(),
          fb = b.stockName?.toLowerCase();

        if (fa === "nonstock") {
          //to make sure that nonstock is the last one
          return 1;
        }

        if (fa && fb) {
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
        }
        return 0;
      });

      setPartList(newPartList);
    }
  }, [
    isAddPart,
    stockLocation.type,
    showSuggestedParts,
    appliedFilter,
    availableParts.engineerParts,
    availableParts.customerParts,
    availableParts.liveSearchParts,
    availableParts.locationalParts,
    job.customer?.id,
    isOnline,
    partSearch,
    suggestedParts,
    equipmentId,
    applyFilters,
  ]);

  useEffect(() => {
    if (isAddPart) {
      setAvailableParts();
    } else {
      let checkedRequestableParts = requestableParts ?? [];

      if (showSuggestedParts) {
        checkedRequestableParts = checkedRequestableParts.filter(
          (p) => suggestedParts[equipmentId]?.findIndex((x) => x === p.id) !== -1
        );
      }

      if (appliedFilter) {
        checkedRequestableParts = applyFilters(checkedRequestableParts);
      }
      setPartList(checkedRequestableParts);
    }
  }, [
    isAddPart,
    availableParts,
    requestableParts,
    setAvailableParts,
    showSuggestedParts,
    appliedFilter,
    applyFilters,
    suggestedParts,
    equipmentId,
  ]);

  useEffect(() => {
    if (scannerValue) {
      const scanMatches = partList.filter(
        (part) => part.barcode === scannerValue || part.partNumber === scannerValue
      );

      if (scanMatches.length === 1) {
        setSelectedPart(scanMatches[0]);
      } else {
        dispatch(addSnackbarMessage({ key: "No-barcode-match", argument: scannerValue }));
      }
      setScannerValue("");
    }
  }, [scannerValue, intl, partList, dispatch]);

  const submitButtonText = isAddPart ? (
    <FormattedMessage id="visit.addPart" />
  ) : (
    <FormattedMessage id="part.requestPart" />
  );

  const quantity = selectedPart?.quantity;
  const isNonStock = selectedPart?.isNonStock;

  const minusButtonDisabled =
    qty === 1 ||
    (isAddPart && !isNonStock && !allowNegativeStock && totalUsedPart >= (quantity || 0));

  const plusButtonDisabled =
    isAddPart &&
    !isNonStock &&
    !allowNegativeStock &&
    (qty === quantity ||
      totalUsedPart >= (quantity || 0) ||
      qty + totalUsedPart >= (quantity || 0));

  return (
    <StyledContainer data-testid="AddPartDialog">
      {hasBarcodeScanner && (
        <BarcodeScannerDialog
          open={openScanner}
          setOpen={(open: boolean) => setOpenScanner(open)}
          submitValue={(value: string) => {
            setScannerValue(value);
            setOpenScanner(false);
          }}
        />
      )}
      <FilterAppBar
        onClick={setFilterDialogOpen}
        filterCount={filterActiveCount()}
        foundCount={partList.length}
        type="parts"
      />
      <Grid container direction="column" spacing={2}>
        <Grid item>
          {isAddPart ? (
            <RadioGroup
              row
              value={stockLocation.type}
              onChange={(event) => {
                const sl = stockCheckboxes.find((x) => x.type === event.target.value);

                if (sl) {
                  setStockLocation(sl);
                  setPartSearch("");
                }
              }}
              name="stock-location"
            >
              {stockCheckboxes.map((checkbox, index) => (
                <FormControlLabel
                  key={`${checkbox.label}-${index}`}
                  value={checkbox.type}
                  control={<Radio className={classes.radioButton} />}
                  label={checkbox.label}
                  disabled={checkbox.disabled}
                />
              ))}
            </RadioGroup>
          ) : (
            <Box p={2.625} /> /*Request - Part */
          )}
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ pt: "10px", pb: "10px" }}
          >
            <Grid item>
              <Typography variant="body1">
                <FormattedMessage id="part.showSuggestedParts" />
              </Typography>
            </Grid>
            <Grid item>
              <AsolviSwitch
                key="suggestedParts"
                data-testid="AddPart-ShowSuggestedToggle"
                checked={showSuggestedParts}
                disabled={cacheLoading.suggestedParts}
                onClick={() => {
                  setShowSuggestedParts(!showSuggestedParts);
                }}
              />
            </Grid>
          </Grid>
          <div className={classes.flex}>
            <Autocomplete
              fullWidth
              data-testid="AddPart-SelectPart"
              options={partList}
              loading={loading}
              groupBy={(part) => {
                const stockStoreTranslatable =
                  part.stockStore === StockStore.Customer
                    ? intl.formatMessage({ id: "general.customer" })
                    : part.stockStore === StockStore.Engineer
                    ? intl.formatMessage({ id: "general.engineer" })
                    : part.stockStore === StockStore.Locational
                    ? intl.formatMessage({ id: "part.locational" })
                    : part.stockStore === StockStore.Nonstock
                    ? intl.formatMessage({ id: "part.nonStock" })
                    : part.stockStore === StockStore.Other
                    ? intl.formatMessage({ id: "part.other" })
                    : part.stockStore;

                return part.stockStore === StockStore.Locational
                  ? part.stockName?.toUpperCase() ?? ""
                  : stockStoreTranslatable?.toUpperCase() ?? "";
              }}
              getOptionDisabled={(part: PartType) =>
                isAddPart && part.quantity === 0 && !allowNegativeStock
              }
              getOptionLabel={(part: PartType) => {
                let label = part.description;

                if (isAddPart && !part.isNonStock) {
                  label += ` (${part.quantity})`;
                }
                return label ?? "";
              }}
              noOptionsText={intl.formatMessage({
                id: "general.noOptions",
              })}
              filterOptions={(options: PartType[], state: FilterOptionsState<PartType>) => {
                return filterPartList(options, state.inputValue);
              }}
              value={selectedPart}
              inputValue={partSearch}
              onInputChange={(_, value) => {
                setPartSearch(value);
              }}
              onChange={(_, value) => changeSelectedPart(value)}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  label={intl.formatMessage({
                    id: "part",
                  })}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              renderOption={(props, part: PartType) => {
                return (
                  <li {...props} key={nanoid()} style={{ display: "block" }}>
                    <Typography variant="body1">
                      {part.description}
                      {isAddPart && !part.isNonStock && ` (${part.quantity})`}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {part.partNumber}
                    </Typography>
                    {allowSalesPrice && part.salesPrice && (
                      <Typography variant="body2" color="textSecondary">
                        <FormattedMessage id="part.salesPrice" />:{" "}
                        {intlFormatPrice(intl, part.salesPrice)}
                      </Typography>
                    )}
                  </li>
                );
              }}
            />
            {hasBarcodeScanner && (
              <IconButton onClick={() => setOpenScanner(true)} size="large">
                <CropFreeIcon />
              </IconButton>
            )}
          </div>
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            value={dialogType}
            exclusive
            onChange={(_, newType: AddPartEnum) => {
              if (newType !== null) {
                setDialogType(newType);
                setSelectedPart(null);
                setTempQty("1");
                setInputError("");
              }
            }}
            size="small"
            className={classes.toggleButtonGroup}
          >
            <ToggleButton
              value={AddPartEnum.AddPart}
              className={classes.toggleButton}
              data-testid="AddPart-UsePartButton"
            >
              {intl.formatMessage({
                id: "part.usePart",
              })}
            </ToggleButton>
            <ToggleButton
              value={AddPartEnum.RequestPart}
              className={classes.toggleButton}
              data-testid="AddPart-RequestPartButton"
            >
              {intl.formatMessage({
                id: "part.requestPart",
              })}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {selectedPart &&
          (selectedPart.isNonStock ||
            allowNegativeStock ||
            totalUsedPart < (selectedPart?.quantity || 0) ||
            !isAddPart) && (
            <Grid item container direction="row" justifyContent="space-around" alignItems="center">
              <Grid item xs={2} textAlign="center">
                <DecreaseButton
                  disabled={minusButtonDisabled}
                  className={minusButtonDisabled ? classes.buttonDisabled : ""}
                  onClick={() => changeQty(-1)}
                />
              </Grid>
              <Grid item xs={7} textAlign="center">
                <StyledTextField
                  value={
                    !selectedPart.isNonStock &&
                    !allowNegativeStock &&
                    totalUsedPart >= (selectedPart.quantity || 0) &&
                    isAddPart
                      ? ""
                      : tempQty
                  }
                  id="general.quantity"
                  type="number"
                  margin="normal"
                  onChange={(event) => changeQtyFromInput(event.target.value)}
                  helperText={inputError}
                  error={inputError !== ""}
                />
              </Grid>
              <Grid item xs={2} textAlign="center">
                <IncreaseButton
                  disabled={plusButtonDisabled}
                  className={plusButtonDisabled ? classes.buttonDisabled : ""}
                  onClick={() => changeQty(1)}
                />
              </Grid>
            </Grid>
          )}
        {isAddPart &&
          selectedPart &&
          !selectedPart.isNonStock &&
          totalUsedPart >= (selectedPart?.quantity || 0) &&
          !allowNegativeStock && (
            <Grid item>
              <Alert severity="error">
                {intl.formatMessage(
                  {
                    id: "visit.part.allPartsUsed",
                  },
                  {
                    qty: selectedPart.quantity,
                    description: selectedPart.description,
                  }
                )}
              </Alert>
            </Grid>
          )}
        <Grid item>
          <PrimaryButton
            key={`${
              selectedPart === null ||
              totalUsedPart >= (selectedPart.quantity || 0) ||
              allowNegativeStock
            }`}
            disabled={
              selectedPart === null ||
              (isAddPart &&
                !selectedPart.isNonStock &&
                totalUsedPart >= (selectedPart.quantity || 0) &&
                !allowNegativeStock)
            }
            onClick={addPartCb}
          >
            {submitButtonText}
          </PrimaryButton>
        </Grid>
        <Grid item>
          <SecondaryButton
            variant="contained"
            onClick={closeDialog}
            data-testid="AddPartDialogFinishButton"
          >
            {intl.formatMessage({
              id: "general.finish",
            })}
          </SecondaryButton>
        </Grid>
      </Grid>
      <FullScreenDialog
        title={`${job.externalId} / ${intl.formatMessage({
          id: "filter.setFilters",
        })}`}
        centerTitle
        goingBack
        open={filterDialogOpen}
        setOpen={setFilterDialogOpen}
        child={
          <AddPartFilterDialog
            filter={filter}
            setFilter={setFilter}
            onReset={() => {
              setFilter(defaultPartFilter);
            }}
            onSubmit={() => {
              setAppliedFilter(filter);
              setFilterDialogOpen(false);
            }}
          />
        }
      />
    </StyledContainer>
  );
};
