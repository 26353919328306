import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { Alert, AppBar, Grid, Link, Tab, Tabs } from "@mui/material";
import { cloneDeep } from "lodash";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { TabPanel } from "components/TabPanel";
import TabToolbarPlaceholder from "components/TabToolbarPlaceholder";
import { isEmpty, toDateString } from "helpers";
import { meterReadingsNotRequiredOrFilled } from "operations/mutations/CompleteVisit";
import { useAppDispatch, useAppSelector } from "store";
import { setAppBar } from "store/slices/menu.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";

import { RelatedJobsTab } from "components/job/RelatedJobsTab";
import { JobDetailTab } from "components/job/details/JobDetailTab";
import { RequestedPartsTab } from "components/job/parts/RequestedPartsTab";

import { selectLoadingRelatedJobs, selectPageSelectedJob } from "store/root.store";
import { isFlagEnabled } from "store/slices/user.store";
import { ChecklistTab } from "./tabs/ChecklistTab";

import { CompleteVisitButton } from "pages/jobs/jobDetails/tabs/CompleteVisitButton";
import { ExtrasTab } from "pages/jobs/jobDetails/tabs/ExtrasTab";
import { FileTab } from "pages/jobs/jobDetails/tabs/FileTab";
import { MeterTab } from "pages/jobs/jobDetails/tabs/MeterTab";
import { NotesTab } from "pages/jobs/jobDetails/tabs/NotesTab";
import { PartsTab } from "pages/jobs/jobDetails/tabs/PartsTab";
import { TimesTab } from "pages/jobs/jobDetails/tabs/TimesTab";
import { VisitTab } from "pages/jobs/jobDetails/tabs/VisitTab";
import { SignoffDialog } from "pages/jobs/jobDetails/tabs/completion/SignoffDialog";
import {
  selectChecklistsIncomplete,
  selectSelectedJobVisit,
  selectTravelTabInvalid,
  setJobTab,
  setVisitValue,
} from "store/slices/jobs.store";

const errorKeys = {
  visit: ["solutionDescription", "actionId1", "actionId2", "actionId3"],
  times: ["startDate", "startTime", "stopDate", "stopTime", "work"],
  extras: [],
  checklists: [],
  parts: [],
  files: [],
  notes: [],
  meters: [],
};

export const JobDetails: FC = () => {
  const tabValue = useAppSelector((s) => s.jobs.jobTab);
  const job = useAppSelector(selectPageSelectedJob);
  const loadingRelatedJobs = useAppSelector(selectLoadingRelatedJobs);
  const { errors, meterReadings, autoEndTime, workTimes, travelTimes, metersNotSaved, checklists } =
    useAppSelector(selectSelectedJobVisit);
  const { files, visits, relatedJobs } = job;
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [hideRelatedJobsNotification, setHideRelatedJobsNotification] = useState(true);
  const extrasEnabled = useAppSelector((s) => isFlagEnabled(s, "Extras"));
  const travelTabInvalid = useAppSelector(selectTravelTabInvalid);
  const checklistsIncomplete = useAppSelector(selectChecklistsIncomplete);
  const [fab, setFab] = useState<JSX.Element>();
  const [openSignoff, setOpenSignoff] = useState(false);

  const invalidTravelTime =
    !isEmpty(travelTimes) &&
    travelTimes[0].startTime !== null &&
    travelTimes[travelTimes.length - 1].stopTime === null;

  const tabHasError = (tabName: keyof typeof errorKeys) => {
    let searchKeys = errorKeys[tabName];
    let currentErrorKeys = Object.keys(errors);
    for (let key of currentErrorKeys) {
      if (searchKeys.some((sub) => key.includes(sub))) return true;
    }
    return false;
  };

  const jobHasIncompletePreWorkChecklists =
    !isEmpty(checklists) &&
    checklists.some(
      (c) =>
        c.hasPreStart &&
        c.checklist.questions
          .filter((x) => x.isRequiredPreStart)
          .some((q) => q.answer === null || q.answer === "0")
    );

  useEffect(() => {
    dispatch(
      setAppBar({
        title: job?.externalId ?? "",
        goingBack: true,
      })
    );
  }, [dispatch, job]);

  useEffect(() => {
    if (!isEmpty(relatedJobs)) {
      setHideRelatedJobsNotification(false);
    }
  }, [relatedJobs]);

  const onCompleteVisit = () => {
    if (invalidTravelTime) {
      dispatch(addSnackbarMessage({ key: "No-end-travel" }));
    } else {
      if (meterReadings && meterReadingsNotRequiredOrFilled(meterReadings, job)) {
        if (autoEndTime) {
          let workTimesCopy = cloneDeep(workTimes);
          let last = workTimesCopy.pop();
          if (last) {
            last.stopDate = toDateString(Date.now());
            last.stopTime = toDateString(Date.now());
            dispatch(setVisitValue({ key: "workTimes", value: [...workTimesCopy, last] }));
          }
        }
        setOpenSignoff(true);
        dispatch(setJobTab({ tab: "visit" }));
      } else {
        dispatch(addSnackbarMessage({ key: "CompleteVisitWithParts-fail-meters" }));
      }
      return null;
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          mt: 7,
          backgroundColor: `primary.main`,
        }}
      >
        <Tabs
          scrollButtons
          value={tabValue}
          onChange={(_, newValue: string) => {
            dispatch(setJobTab({ tab: newValue }));
          }}
          variant="scrollable"
          textColor="inherit"
        >
          <Tab label={intl.formatMessage({ id: "general.details" })} value="details" />
          {!isEmpty(visits) && (
            <Tab label={intl.formatMessage({ id: "general.visits" })} value="visits" />
          )}
          {!isEmpty(relatedJobs) && (
            <Tab label={intl.formatMessage({ id: "general.relatedJobs" })} value="relatedJobs" />
          )}
          {!isEmpty(job.preOrderedParts) && (
            <Tab
              label={
                <>
                  {intl.formatMessage({ id: "job.parts" })}
                  {tabHasError("parts") ? "*" : null}
                </>
              }
              value="parts"
              data-testid="partsTab"
            />
          )}
          {!!job.checklists?.length &&
            job.checklists.some((checklist) =>
              checklist.questions.some((q) => q.isRequiredPreStart)
            ) && (
              <Tab
                label={
                  <>
                    {intl.formatMessage({ id: "job.checklists" })}
                    {tabHasError("checklists") || checklistsIncomplete ? "*" : null}
                  </>
                }
                value="checklists"
                data-testid="checklistsTab"
              />
            )}
          {!isEmpty(files) && (
            <Tab
              label={
                <>
                  {intl.formatMessage({ id: "general.files" })}
                  {tabHasError("files") ? "*" : null}
                </>
              }
              value="files"
              data-testid="filesTab"
            />
          )}
          <Tab
            label={
              <>
                {intl.formatMessage({ id: "job.times" })}
                {tabHasError("times") || travelTabInvalid ? "*" : null}
              </>
            }
            value="times"
            data-testid="timesTab"
          />
          {extrasEnabled && (
            <Tab
              label={
                <>
                  {intl.formatMessage({ id: "job.extras" })}
                  {tabHasError("extras") ? "*" : null}
                </>
              }
              value="extras"
              data-testid="extrasTab"
              disabled={jobHasIncompletePreWorkChecklists}
            />
          )}
          {isEmpty(job.preOrderedParts) && (
            <Tab
              label={
                <>
                  {intl.formatMessage({ id: "job.parts" })}
                  {tabHasError("parts") ? "*" : null}
                </>
              }
              value="parts"
              data-testid="partsTab"
              disabled={jobHasIncompletePreWorkChecklists}
            />
          )}
          {!!job.checklists?.length &&
            !job.checklists.some((checklist) =>
              checklist.questions.some((q) => q.isRequiredPreStart)
            ) && (
              <Tab
                label={
                  <>
                    {intl.formatMessage({ id: "job.checklists" })}
                    {tabHasError("checklists") || checklistsIncomplete ? "*" : null}
                  </>
                }
                value="checklists"
                data-testid="checklistsTab"
              />
            )}
          {isEmpty(files) && (
            <Tab
              label={
                <>
                  {intl.formatMessage({ id: "general.files" })}
                  {tabHasError("files") ? "*" : null}
                </>
              }
              value="files"
              data-testid="filesTab"
              disabled={jobHasIncompletePreWorkChecklists}
            />
          )}
          <Tab
            label={
              <>
                {intl.formatMessage({ id: "job.notes" })}
                {tabHasError("notes") ? "*" : null}
              </>
            }
            value="notes"
            data-testid="notesTab"
            disabled={jobHasIncompletePreWorkChecklists}
          />
          {job.meters && !!job.meters?.length && (
            <Tab
              label={
                <>
                  {intl.formatMessage({ id: "job.meters" })}
                  {tabHasError("meters") || metersNotSaved ? "*" : null}
                </>
              }
              value="meters"
              data-testid="metersTab"
              disabled={jobHasIncompletePreWorkChecklists}
            />
          )}
          <Tab
            label={
              <>
                {intl.formatMessage({ id: "general.visit" })}
                {tabHasError("visit") ? "*" : null}
              </>
            }
            className="e2e-visit-tab"
            value="visit"
            data-testid="visitTab"
            disabled={jobHasIncompletePreWorkChecklists}
          />
        </Tabs>
      </AppBar>
      <TabToolbarPlaceholder />

      {!hideRelatedJobsNotification && !isEmpty(relatedJobs) && (
        <Link
          component="button"
          onClick={() => dispatch(setJobTab({ tab: "relatedJobs" }))}
          sx={{
            width: 1,
          }}
          data-testid="JobDetails-RelatedJobs"
        >
          <Alert
            severity="info"
            sx={{
              width: 1,
              borderRadius: 0,
              backgroundColor: "info.main",
              color: "common.white",
            }}
            action={<ChevronRightIcon />}
          >
            <FormattedMessage id="general.otherJobsOpen" />
          </Alert>
        </Link>
      )}

      <TabPanel index="details" value={tabValue}>
        <JobDetailTab setFab={setFab} />
      </TabPanel>
      <TabPanel index="requestedParts" value={tabValue}>
        <RequestedPartsTab parts={job.preOrderedParts} />
      </TabPanel>
      <TabPanel index="relatedJobs" value={tabValue}>
        <RelatedJobsTab
          jobs={relatedJobs}
          loading={loadingRelatedJobs}
          hideNotification={setHideRelatedJobsNotification}
          customerId={job.customer?.id}
          equipmentId={job.equipment?.id as string}
        />
      </TabPanel>
      <TabPanel index="times" value={tabValue}>
        <TimesTab setFab={setFab} />
      </TabPanel>
      <TabPanel index="extras" value={tabValue}>
        <ExtrasTab setFab={setFab} />
      </TabPanel>
      <TabPanel index="parts" value={tabValue}>
        <PartsTab setFab={setFab} />
      </TabPanel>
      <TabPanel index="files" value={tabValue}>
        <FileTab setFab={setFab} />
      </TabPanel>
      <TabPanel index="notes" value={tabValue}>
        <NotesTab setFab={setFab} />
      </TabPanel>
      <TabPanel index="meters" value={tabValue}>
        <MeterTab setFab={setFab} />
      </TabPanel>
      <TabPanel index="checklists" value={tabValue}>
        <ChecklistTab addButton setFab={setFab} />
      </TabPanel>
      <TabPanel index="visit" value={tabValue}>
        <VisitTab handleFinished={() => navigate(-1)} setFab={setFab} />
      </TabPanel>
      <Grid
        container
        direction="column"
        width="inherit"
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
        }}
      >
        <Grid item>{fab}</Grid>
        <Grid item>
          <CompleteVisitButton onCompleteVisit={onCompleteVisit} />
        </Grid>
      </Grid>
      <SignoffDialog open={openSignoff} handleClose={() => setOpenSignoff(false)} />
    </>
  );
};
