import { CircularProgress, Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { AsolviSwitch } from "components/AsolviSwitch";
import BackdropWhite from "components/BackdropWhite";
import PrimaryButton from "components/PrimaryButton";
import { toDateString } from "helpers";
import { MeterReadingInputType } from "operations/schema/schema";

import { useAppDispatch, useAppSelector } from "store";
import {
  selectSelectedJob,
  selectSelectedJobVisit,
  setMetersNotSaved,
  setMetersUseCurrent,
  updateMeterReadings,
  updateMeters,
} from "store/slices/jobs.store";

import { addSnackbarMessage } from "store/slices/snackbar.store";
import { TabProps } from "./TabProps";
import { MeterReadingComponent, MeterValidation } from "./meter/MeterReading";

const classes = {
  e2eSaveMetersButton: `e2e-save-meters-button`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.e2eSaveMetersButton}`]: {
    fontSize: "1rem",
    textTransform: "capitalize",
  },
}));

export const MeterTab: FC<TabProps> = (props) => {
  const { setFab } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectSelectedJob);
  const { meterReadings, metersUseCurrent, metersNotSaved } =
    useAppSelector(selectSelectedJobVisit);
  const [loading, setLoading] = useState(false);
  const [componentLoading, setComponentLoading] = useState(true);

  const isValid = () => {
    return errors.every((e) => e.valid);
  };

  const meters = job?.meters?.slice() || [];

  const initialCurrentReadings = meters.map((meter) => {
    return {
      currentReading: 0,
      currentReadingDate: toDateString(new Date()),
      typeId: meter?.typeId,
    } as MeterReadingInputType;
  });

  const [readings, setReadings] = useState<MeterReadingInputType[]>(initialCurrentReadings);

  //set initial state of readings to not have errors
  const [errors, setErrors] = useState<MeterValidation[]>(
    initialCurrentReadings?.map((reading) => ({
      typeId: reading.typeId,
      valid: true,
    }))
  );

  useEffect(() => {
    const fab = undefined;
    if (setFab) {
      setFab(fab);
    }
  }, [setFab]);

  const initializeComponent = () => {
    if (componentLoading) {
      let meters = meterReadings;
      setReadings(meters && meters.length ? meters : initialCurrentReadings ?? []);
      setComponentLoading(false);
    }
  };

  const setErrorsCb = useCallback(
    (meterType: MeterValidation) => {
      setErrors((prevErrors) =>
        prevErrors?.map((error) => {
          return error.typeId === meterType.typeId ? { ...error, valid: meterType.valid } : error;
        })
      );
    },
    [setErrors]
  );

  const toggleUsePreviousReading = () => {
    let newUseCurrent = !metersUseCurrent;
    if (newUseCurrent) {
      refreshMeterReadings();
    }

    setErrors((prevErrors) =>
      prevErrors?.map((error) => {
        return { ...error, valid: true };
      })
    );
    dispatch(setMetersUseCurrent({ useCurrent: newUseCurrent }));
  };

  const updateMeterReadingCb = (readings: MeterReadingInputType[]) => {
    readings = readings.filter((x) => x.currentReading || x.currentReading === 0);
    dispatch(updateMeters({ jobId: job.id, meters: readings })); // Send meters with graphQL
    dispatch(updateMeterReadings({ readings })); // Saved locally for persistent view
    setLoading(false);
    dispatch(addSnackbarMessage({ key: "Meters-Updated" }));
  };

  const refreshMeterReadings = () => {
    const newReadings = readings.map((reading) => {
      const meter = meters.find((m) => m?.typeId === reading.typeId);
      const currentReading = meter?.currentReading?.reading ?? meter?.previousReading?.reading ?? 0;

      return {
        ...reading,
        currentReading,
      };
    });
    setReadings(newReadings);
  };

  initializeComponent();

  return componentLoading ? null : (
    <StyledContainer data-testid="MeterTab">
      <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap" mt={2.5}>
        <Grid item xs={10}>
          <Typography>
            {intl.formatMessage({ id: "visit.meters.unableToObtainReadings" })}
          </Typography>
        </Grid>
        <Grid item>
          <AsolviSwitch
            checked={metersUseCurrent}
            onChange={() => {
              dispatch(setMetersNotSaved({ unSavedChanges: true }));
              toggleUsePreviousReading();
            }}
            name="useCurrentReading"
            data-testid="UseCurrentButton"
          />
        </Grid>
      </Grid>
      {meters.map((meter, index) => (
        <MeterReadingComponent
          key={`${index}-${meter?.name}`}
          meter={meter}
          setError={setErrorsCb}
          readings={readings}
          setReadings={setReadings}
          index={index}
          useCurrent={metersUseCurrent}
          required={(job.equipment?.serialNumber && job.requireMeterReading) || false}
        />
      ))}
      {meters.length! > 0 && (
        <>
          <BackdropWhite
            open={loading}
            onClick={() => setLoading(false)}
            data-testid="BackdropLoading"
          >
            <CircularProgress color="inherit" />
          </BackdropWhite>
          <PrimaryButton
            key={`${errors}`}
            className={classes.e2eSaveMetersButton}
            variant="contained"
            fullWidth
            disabled={!isValid() || !metersNotSaved}
            onClick={() => {
              setLoading(true);
              updateMeterReadingCb(readings);
              dispatch(setMetersNotSaved({ unSavedChanges: false }));
            }}
            sx={{ mb: 1.5 }}
            data-testid="MeterTabSaveButton"
          >
            {intl.formatMessage({ id: "general.save" })}
          </PrimaryButton>
        </>
      )}
    </StyledContainer>
  );
};
