import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import { differenceInHours } from "date-fns";
import { AvailablePartsSearch } from "models/AvailablePartsSearch";
import { PartQueryInputType, StockStore } from "operations/schema/schema";
import { AppAsyncThunkConfig } from "store";
import { State } from "./cache.store";

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

export const asyncQueries = {
  getCachePrefill: createAppAsyncThunk(
    "cache/getCachePrefill",
    async (props: { force?: boolean }, { getState, rejectWithValue, extra: { sdk } }) => {
      const { lastLoaded } = getState().cache;
      if (!props.force && lastLoaded && differenceInHours(new Date(lastLoaded), new Date()) < 72) {
        return rejectWithValue("No need to refresh yet");
      }
      const { data, errors } = await graphqlRequest(sdk.getCachePrefill);
      if (errors) return rejectWithValue(errors);
      if (!data?.jobActions) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getActions: createAppAsyncThunk(
    "cache/getActions",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getActions);
      if (errors) return rejectWithValue(errors);
      if (!data?.jobActions) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getJobCategories: createAppAsyncThunk(
    "cache/getJobCategories",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getJobCategories);
      if (errors) return rejectWithValue(errors);
      if (!data?.jobCategories) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getExtras: createAppAsyncThunk(
    "cache/getExtras",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getExtras);
      if (errors) return rejectWithValue(errors);
      if (!data?.extras) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getAvailableParts: createAppAsyncThunk(
    "cache/getAvailableParts",
    async (props: AvailablePartsSearch, { rejectWithValue, extra: { sdk } }) => {
      const { location, searchString } = props;
      const { data, errors } = await graphqlRequest(sdk.getAvailableParts, {
        variables: {
          //TODO; Necessary to handle multiple if all we do is query one at a time?
          locations: [location],
          searchString: searchString,
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.parts) return rejectWithValue("something went wrong");
      return { data, props };
    }
  ),
  getPartsEngineer: createAppAsyncThunk(
    "cache/getPartsEngineer",
    async (props: { force?: boolean }, { getState, rejectWithValue, extra: { sdk } }) => {
      const {
        cache: { engineerPartsLastLoaded },
        user: { userVar },
      } = getState();
      if (
        !props.force &&
        engineerPartsLastLoaded &&
        differenceInHours(new Date(engineerPartsLastLoaded), new Date()) < 1
      ) {
        return rejectWithValue("No need to refresh yet");
      }
      const { data, errors } = await graphqlRequest(sdk.getAvailableParts, {
        variables: {
          locations: [
            {
              locationId: userVar?.stockId,
              stockStore: StockStore.Engineer,
            },
          ],
          searchString: "",
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.parts) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getPartsLocational: createAppAsyncThunk(
    "cache/getPartsLocational",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getAvailableParts, {
        variables: {
          //TODO; Necessary to handle multiple if all we do is query one at a time?
          locations: [{ locationId: "", stockStore: StockStore.Locational }],
          searchString: "",
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.parts) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getPartSortGroups: createAppAsyncThunk(
    "cache/getPartSortGroups",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getPartSortGroups);
      if (errors) return rejectWithValue(errors);
      if (!data?.partSortGroups) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getPlannerNodes: createAppAsyncThunk(
    "cache/getPlannerNodes",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getPlannerNodes);
      if (errors) return rejectWithValue(errors);
      if (!data?.plannerNodes) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getRequestableParts: createAppAsyncThunk(
    "cache/getRequestableParts",
    async (
      props: { location: PartQueryInputType; force?: boolean },
      { getState, rejectWithValue, extra: { sdk } }
    ) => {
      const { requestablePartsLastLoaded } = getState().cache;
      if (
        !props.force &&
        requestablePartsLastLoaded &&
        differenceInHours(new Date(requestablePartsLastLoaded), new Date()) < 1
      ) {
        return rejectWithValue("No need to refresh yet");
      }
      const { data, errors } = await graphqlRequest(sdk.getRequestableParts, {
        variables: {
          locations: [props.location],
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.requestableParts) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getSuggestedParts: createAppAsyncThunk(
    "cache/getSuggestedParts",
    async (variables: { equipmentId: string }, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getSuggestedParts, {
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.suggestedParts) return rejectWithValue("something went wrong");
      return { variables, data };
    }
  ),
  getSuppliers: createAppAsyncThunk(
    "cache/getSuppliers",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getSuppliers);
      if (errors) return rejectWithValue(errors);
      if (!data?.suppliers) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getSymptoms: createAppAsyncThunk(
    "cache/getSymptoms",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getSymptoms);
      if (errors) return rejectWithValue(errors);
      if (!data?.symptoms) return rejectWithValue("something went wrong");
      return data;
    }
  ),
};

export const queryBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncQueries.getCachePrefill.pending, (state) => {
    state.loading.prefill = true;
    return state;
  });
  builder.addCase(asyncQueries.getCachePrefill.rejected, (state) => {
    state.loading.prefill = false;
    return state;
  });
  builder.addCase(asyncQueries.getCachePrefill.fulfilled, (state, { payload: data }) => {
    state.lastLoaded = new Date().toISOString();
    state.loading.prefill = false;
    state.actions = [...data.jobActions];
    state.extras = [...data.extras];
    state.jobCategories = [...data.jobCategories];
    state.partSortGroups = { ...data.partSortGroups };
    state.suppliers = [...data.suppliers];
    state.symptoms = [...data.symptoms];
    return state;
  });
  builder.addCase(asyncQueries.getActions.pending, (state) => {
    state.loading.actions = true;
    return state;
  });
  builder.addCase(asyncQueries.getActions.rejected, (state) => {
    state.loading.actions = false;
    return state;
  });
  builder.addCase(asyncQueries.getActions.fulfilled, (state, { payload: data }) => {
    state.loading.actions = false;
    state.actions = [...data.jobActions];
    return state;
  });
  builder.addCase(asyncQueries.getJobCategories.pending, (state) => {
    state.loading.jobCategories = true;
    return state;
  });
  builder.addCase(asyncQueries.getJobCategories.rejected, (state) => {
    state.loading.jobCategories = false;
    return state;
  });
  builder.addCase(asyncQueries.getJobCategories.fulfilled, (state, { payload: data }) => {
    state.loading.jobCategories = false;
    state.jobCategories = [...data.jobCategories];
    return state;
  });
  builder.addCase(asyncQueries.getExtras.pending, (state) => {
    state.loading.extras = true;
    return state;
  });
  builder.addCase(asyncQueries.getExtras.rejected, (state) => {
    state.loading.extras = false;
    return state;
  });
  builder.addCase(asyncQueries.getExtras.fulfilled, (state, { payload: data }) => {
    state.loading.extras = false;
    state.extras = [...data.extras];
    return state;
  });
  builder.addCase(asyncQueries.getAvailableParts.pending, (state) => {
    state.loading.availableParts = true;
    return state;
  });
  builder.addCase(asyncQueries.getAvailableParts.rejected, (state) => {
    state.loading.availableParts = false;
    return state;
  });
  builder.addCase(
    asyncQueries.getAvailableParts.fulfilled,
    (
      state,
      {
        payload: {
          data,
          props: { location, searchString },
        },
      }
    ) => {
      state.loading.availableParts = false;
      if (location.stockStore === StockStore.Engineer) {
        state.availableParts.engineerParts = [...data.parts];
        state.engineerPartsLastLoaded = new Date().toISOString();
      } else if (location.stockStore === StockStore.Customer) {
        const customerId = location.locationId;
        if (customerId) {
          state.availableParts.customerParts[customerId] = [...data.parts];
        }
      } else {
        if (searchString && searchString?.length > 0) {
          state.availableParts.liveSearchParts = [...data.parts];
        } else {
          state.availableParts.locationalParts = [...data.parts];
        }
      }
      return state;
    }
  );
  builder.addCase(asyncQueries.getPartsEngineer.pending, (state) => {
    state.loading.engineerParts = true;
    return state;
  });
  builder.addCase(asyncQueries.getPartsEngineer.rejected, (state) => {
    state.loading.engineerParts = false;
    return state;
  });
  builder.addCase(asyncQueries.getPartsEngineer.fulfilled, (state, { payload: data }) => {
    state.loading.engineerParts = false;
    state.availableParts.engineerParts = [...data.parts];
    state.engineerPartsLastLoaded = new Date().toISOString();
    return state;
  });
  builder.addCase(asyncQueries.getPartsLocational.pending, (state) => {
    state.loading.locationalParts = true;
    return state;
  });
  builder.addCase(asyncQueries.getPartsLocational.rejected, (state) => {
    state.loading.locationalParts = false;
    return state;
  });
  builder.addCase(asyncQueries.getPartsLocational.fulfilled, (state, { payload: data }) => {
    state.loading.locationalParts = false;
    state.availableParts.locationalParts = [...data.parts];
    return state;
  });
  builder.addCase(asyncQueries.getPartSortGroups.pending, (state) => {
    state.loading.partSortGroups = true;
    return state;
  });
  builder.addCase(asyncQueries.getPartSortGroups.rejected, (state) => {
    state.loading.partSortGroups = false;
    return state;
  });
  builder.addCase(asyncQueries.getPartSortGroups.fulfilled, (state, { payload: data }) => {
    state.loading.partSortGroups = false;
    state.partSortGroups = { ...data.partSortGroups };
    return state;
  });
  builder.addCase(asyncQueries.getPlannerNodes.pending, (state) => {
    state.loading.plannerNodes = true;
    return state;
  });
  builder.addCase(asyncQueries.getPlannerNodes.rejected, (state) => {
    state.loading.plannerNodes = false;
    return state;
  });
  builder.addCase(asyncQueries.getPlannerNodes.fulfilled, (state, { payload: data }) => {
    state.loading.plannerNodes = false;
    state.plannerNodes = [...data.plannerNodes];
    return state;
  });
  builder.addCase(asyncQueries.getRequestableParts.pending, (state) => {
    state.loading.requestableParts = true;
    return state;
  });
  builder.addCase(asyncQueries.getRequestableParts.rejected, (state) => {
    state.loading.requestableParts = false;
    return state;
  });
  builder.addCase(asyncQueries.getRequestableParts.fulfilled, (state, { payload: data }) => {
    state.loading.requestableParts = false;
    state.requestableParts = [...data.requestableParts];
    state.requestablePartsLastLoaded = new Date().toISOString();
    return state;
  });
  builder.addCase(asyncQueries.getSuggestedParts.pending, (state) => {
    state.loading.suggestedParts = true;
    return state;
  });
  builder.addCase(asyncQueries.getSuggestedParts.rejected, (state) => {
    state.loading.suggestedParts = false;
    return state;
  });
  builder.addCase(asyncQueries.getSuggestedParts.fulfilled, (state, { payload }) => {
    state.loading.suggestedParts = false;
    state.suggestedParts[payload.variables.equipmentId] = [...payload.data.suggestedParts];
    return state;
  });
  builder.addCase(asyncQueries.getSuppliers.pending, (state) => {
    state.loading.suppliers = true;
    return state;
  });
  builder.addCase(asyncQueries.getSuppliers.rejected, (state) => {
    state.loading.suppliers = false;
    return state;
  });
  builder.addCase(asyncQueries.getSuppliers.fulfilled, (state, { payload: data }) => {
    state.loading.suppliers = false;
    state.suppliers = [...data.suppliers];
    return state;
  });
  builder.addCase(asyncQueries.getSymptoms.pending, (state) => {
    state.loading.symptoms = true;
    return state;
  });
  builder.addCase(asyncQueries.getSymptoms.rejected, (state) => {
    state.loading.symptoms = false;
    return state;
  });
  builder.addCase(asyncQueries.getSymptoms.fulfilled, (state, { payload: data }) => {
    state.loading.symptoms = false;
    state.symptoms = [...data.symptoms];
    return state;
  });
};
