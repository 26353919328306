import { Edit as EditIcon, LockClock } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { intlFormatDateTimeWithWeekday } from "helpers";
import { PlannedDateEditDialog } from "pages/jobs/dialogs/PlannedDateEditDialog";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";
import { setUpdatePlannedDateOpen } from "store/slices/jobs.store";

const PREFIX = "JobDetailTab";

const classes = {
  leftIconAdjust: `${PREFIX}-left-icon-adjust`,
};

interface DetailsTimeDisplayProps {
  readonly?: boolean;
}

export const DetailsTimeDisplay: FC<DetailsTimeDisplayProps> = (props: DetailsTimeDisplayProps) => {
  const { readonly } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectPageSelectedJob);

  const plannedDate = job.plannedDate;
  const responseDate = job.responseDate;

  const editPlannedString =
    job.plannedDate?.startTime || job.plannedDate?.stopTime
      ? intl.formatMessage({ id: "visit.changePlannedTimes" })
      : intl.formatMessage({ id: "visit.addPlannedTimes" });

  const responseString = responseDate ? (
    <>
      <LockClock className={classes.leftIconAdjust} sx={{ mr: 1.75 }} />
      {intlFormatDateTimeWithWeekday(intl, responseDate)}
    </>
  ) : (
    <></>
  );

  const labelDisplay = () => {
    if (plannedDate) {
      if (plannedDate.startTime) return intl.formatMessage({ id: "visit.planned" });
    } else if (responseDate) {
      return responseString;
    }

    return intl.formatMessage({ id: "general.unplanned" });
  };

  return (
    <>
      <Typography
        variant="h6"
        sx={{
          fontSize: "1.2rem",
        }}
        color="black"
        data-testid="DetailsTimeDisplay"
      >
        {labelDisplay()}
        {!readonly && (
          <IconButton
            aria-label={editPlannedString}
            onClick={() => dispatch(setUpdatePlannedDateOpen({ open: true }))}
            data-testid="DetailsTimeDisplay-EditPlannedButton"
            sx={{
              fontSize: "1.2rem",
              paddingY: 0,
            }}
          >
            <EditIcon color="primary" />
          </IconButton>
        )}
      </Typography>

      {plannedDate?.startTime && (
        <>
          <Typography sx={{ marginLeft: "38px" }}>
            {intlFormatDateTimeWithWeekday(intl, plannedDate.startTime)}
          </Typography>
          {plannedDate.stopTime && (
            <>
              <Typography sx={{ marginLeft: "38px" }}>
                {intl.formatMessage({ id: "times.to" })}
              </Typography>
              <Typography sx={{ marginLeft: "38px" }}>
                {intlFormatDateTimeWithWeekday(intl, plannedDate.stopTime)}
              </Typography>
            </>
          )}
        </>
      )}
      {plannedDate && responseDate && (
        <Typography variant="body2" color="secondary">
          {responseString}
        </Typography>
      )}
      {!readonly && <PlannedDateEditDialog />}
    </>
  );
};
