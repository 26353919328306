import { Autocomplete, CircularProgress, FormHelperText, Grid } from "@mui/material";
import StyledTextField from "components/StyledTextField";
import { findMatchingCode } from "helpers";
import { ActionType } from "operations/schema/schema";
import { FC, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "store";
import { selectSelectedJobVisit, setVisitValue } from "store/slices/jobs.store";

export interface ActionAutocompleteProps {
  actions?: ActionType[];
  loading: boolean;
}

export const ActionAutocomplete: FC<ActionAutocompleteProps> = (props) => {
  const { actions = [], loading } = props;
  const dispatch = useAppDispatch();
  const { errors, actionId1, actionId2, actionId3 } = useAppSelector(selectSelectedJobVisit);
  const intl = useIntl();
  const actions1 = useMemo(() => actions.filter((a) => a.sortId === "1"), [actions]);
  const [actions2, setActions2] = useState<ActionType[]>(() =>
    actions.filter((a) => a.sortId === "2")
  );
  const [actions3, setActions3] = useState<ActionType[]>(() =>
    actions.filter((a) => a.sortId === "3")
  );

  return (
    <>
      <Grid item>
        <Autocomplete
          fullWidth
          loading={loading}
          options={actions1}
          data-testid="actions1"
          getOptionLabel={(action: ActionType) => `${action.code} - ${action.name}`}
          onChange={(_, value: ActionType | null) => {
            dispatch(setVisitValue({ key: "actionId1", value: value?.id }));
            dispatch(setVisitValue({ key: "actionId2", value: null }));
            dispatch(setVisitValue({ key: "actionId3", value: null }));

            const filteredActions = actions.filter((a) =>
              value?.subActionCodes?.includes(a.code as string)
            );

            setActions2(
              !!filteredActions.length ? filteredActions : actions.filter((a) => a.sortId === "2")
            );
          }}
          value={findMatchingCode(actions1, actionId1)}
          renderInput={(params) => (
            <StyledTextField
              error={!!errors?.actionId1}
              name="actionId1"
              className="actionId1"
              {...params}
              label={intl.formatMessage({ id: "job.action" })}
              variant="outlined"
              required
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <FormHelperText error>{errors?.actionId1}</FormHelperText>
      </Grid>
      {actionId1 && !!actions2.length && (
        <Grid item>
          <Autocomplete
            fullWidth
            options={actions2}
            data-testid="actions2"
            getOptionLabel={(action: ActionType) => `${action.code} - ${action.name}`}
            onChange={(_, value: ActionType | null) => {
              dispatch(setVisitValue({ key: "actionId2", value: value?.id }));
              dispatch(setVisitValue({ key: "actionId3", value: null }));

              const filteredActions = actions.filter((a) =>
                value?.subActionCodes?.includes(a.code as string)
              );

              setActions3(
                !!filteredActions.length ? filteredActions : actions.filter((a) => a.sortId === "3")
              );
            }}
            value={findMatchingCode(actions2, actionId2)}
            renderInput={(params) => (
              <StyledTextField
                name="actionId2"
                className="actionId2"
                label={`${intl.formatMessage({ id: "job.action" })} 2`}
                {...params}
              />
            )}
          />
        </Grid>
      )}
      {actionId2 && !!actions3.length && (
        <Grid item>
          <Autocomplete
            fullWidth
            options={actions3}
            data-testid="actions3"
            getOptionLabel={(action: ActionType) => `${action.code} - ${action.name}`}
            onChange={(_, value: ActionType | null) => {
              dispatch(setVisitValue({ key: "actionId3", value: value?.id }));
            }}
            value={findMatchingCode(actions3, actionId3)}
            renderInput={(params) => (
              <StyledTextField
                name="actionId3"
                className="actionId3"
                label={`${intl.formatMessage({ id: "job.action" })} 3`}
                {...params}
              />
            )}
          />
        </Grid>
      )}
    </>
  );
};
