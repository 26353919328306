import { CircularProgress } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { loginAuth0, setAuth } from "store/slices/user.store";

import { getAuth0, initializeAuth0 } from "context/auth0";
import { Auth0LoginMutationVariables } from "operations/schema/schema";

import { unwrapResult } from "@reduxjs/toolkit";
import { LoginContainer } from "components/LoginContainer";
import { isAbortError } from "helpers";
import { addSnackbarMessage } from "store/slices/snackbar.store";

export const Auth0Callback: FC = () => {
  const { authVar, auth0Var } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState("");
  const auth0Props = JSON.parse(localStorage.getItem("auth0Props") || "{}");
  const [url] = useState(auth0Var.url || auth0Props.url || "");
  const [domain] = useState(auth0Var.domain || auth0Props.domain || "");
  const [clientId] = useState(auth0Var.clientId || auth0Props.clientId || "");

  const [callbackHandled, setCallbackHandled] = useState(false);

  const handleLoginError = useCallback(
    (e: any) => {
      navigate("/login");
      switch (e[0].message) {
        case "no-mobile-license":
          dispatch(addSnackbarMessage({ key: "Login-failed-noMobileLicense" }));
          break;
        default:
          dispatch(addSnackbarMessage({ key: "Login-failed" }));
          break;
      }
    },
    [navigate, dispatch]
  );

  const auth0Login = useCallback(
    async (variables: Auth0LoginMutationVariables) => {
      await dispatch(loginAuth0(variables))
        .then(unwrapResult)
        .then(() => {
          navigate("/jobs");
        })
        .catch((e) => {
          if (isAbortError(e)) return;
          handleLoginError(e);
        });
    },
    [dispatch, handleLoginError, navigate]
  );

  useEffect(() => {
    if (!getAuth0() && domain && clientId && url) {
      initializeAuth0();
    }
  }, [domain, clientId, url]);

  useEffect(() => {
    const handleResult = async () => {
      let auth0 = getAuth0();
      if (!auth0) return;
      let { audience: a } = auth0Var;
      let cb;
      try {
        cb = await auth0.handleRedirectCallback();
      } catch (_) {}
      if (!cb) return;
      // * Keep in mind, this method does NOT give you the `refresh_token`
      // For some reason it is excluded, but we fetch it directly from the localstore later in `getRefreshToken()`
      let token = await auth0.getTokenSilently({
        audience: a || "",
        detailedResponse: true,
      });
      if (!token) throw Error("No token received");
      setAccessToken(token.access_token);
      setCallbackHandled(true);
    };
    if (getAuth0() && !callbackHandled) {
      try {
        handleResult();
      } catch (e) {
        console.log(e);
        dispatch(
          setAuth({
            ...authVar,
            message: "Auth0 failed",
          })
        );
        handleLoginError(e);
      }
    }
  }, [auth0Var, authVar, callbackHandled, dispatch, handleLoginError]);

  const getRefreshToken = (): string => {
    // ! If you're not getting the correct data, double check what is actually in the local store and what you're fetching
    // ! console.log everything, there might be two entries that looks the same, but aren't
    const entry = Object.entries(localStorage).find((key) => key[0].includes("auth0spajs"));

    if (entry) {
      const body = JSON.parse(entry[1]);

      return body?.body?.refresh_token ?? "";
    }

    return "";
  };

  useEffect(() => {
    if (accessToken) {
      // ! Keep in mind, if any of these values are missing, it WILL break
      auth0Login({
        url: url,
        email: auth0Var.email,
        accessToken: accessToken,
        refreshToken: getRefreshToken(),
      });
    }
  }, [accessToken, url, auth0Login, auth0Var.email]);

  return (
    <LoginContainer>
      <CircularProgress size={24} />
    </LoginContainer>
  );
};
