import { isValid } from "date-fns";
import * as yup from "yup";

import { getIntl } from "context/SettingsProvider";
import { DefinedDate, mergeDateTime } from "helpers";

export const VisitValidationSchema = (autoEndTime: boolean) => {
  const intl = getIntl();
  return yup.object({
    workTimes: yup
      .array()
      .required()
      .min(1)
      .of(
        yup.object().shape({
          startDate: yup
            .string()
            .required(intl.formatMessage({ id: "times.startDateRequired" }))
            .test({
              name: "type",
              message: intl.formatMessage({ id: "validation.date" }),
              test: function (value) {
                if (!value) {
                  return false;
                }
                return isValid(new Date(value));
              },
            }),
          startTime: yup
            .string()
            .required(intl.formatMessage({ id: "times.startTimeRequired" }))
            .test({
              name: "type",
              message: intl.formatMessage({ id: "validation.time" }),
              test: function (value) {
                if (!value) {
                  return false;
                }
                return isValid(new Date(value));
              },
            })
            .test({
              name: "max",
              message: intl.formatMessage({
                id: "times.startTimeCannotBeFuture",
              }),
              test: function (value) {
                if (!value) {
                  return true;
                }
                let currentDateTime = new Date();
                const dateTimeStart = mergeDateTime(this.parent.startDate, value);
                return currentDateTime > dateTimeStart;
              },
            }),
          stopDate: yup.string().when("startDate", {
            is: (startDate: string) => {
              return isValid(new Date(startDate));
            },
            then: yup
              .string()
              .test({
                name: "required",
                message: intl.formatMessage({ id: "visit.endDateRequired" }),
                test: function (value) {
                  return autoEndTime || !!value;
                },
              })
              .test({
                name: "type",
                message: intl.formatMessage({ id: "validation.date" }),
                test: function (value) {
                  if (!value) {
                    return autoEndTime;
                  }
                  return isValid(new Date(value));
                },
              })
              .test({
                name: "min",
                message: intl.formatMessage({
                  id: "visit.endDateAfterStartDate",
                }),
                test: function (value) {
                  if (autoEndTime) {
                    return true;
                  }
                  // Make copies when mutating the dates or we might accidentally set the times to midnight.
                  const stopDateCopy = new Date(value!);
                  stopDateCopy.setHours(0, 0, 0, 0);
                  const startDateCopy = new Date(this.parent.startDate);
                  startDateCopy.setHours(0, 0, 0, 0);
                  return stopDateCopy >= startDateCopy;
                },
              }),
          }),
          stopTime: yup.string().when(["startTime", "startDate", "stopDate"], {
            is: (startTime: DefinedDate, startDate: DefinedDate, stopDate: DefinedDate) => {
              return (
                isValid(new Date(startTime)) &&
                isValid(new Date(startDate)) &&
                isValid(new Date(stopDate))
              );
            },
            then: yup
              .string()
              .test({
                name: "required",
                message: intl.formatMessage({ id: "visit.endTimeRequired" }),
                test: function (value) {
                  return autoEndTime || !!value;
                },
              })
              .test({
                name: "type",
                message: intl.formatMessage({ id: "validation.time" }),
                test: function (value) {
                  if (!value) {
                    return autoEndTime;
                  }
                  return isValid(new Date(value));
                },
              })
              .test({
                name: "min",
                message: intl.formatMessage({
                  id: "visit.endTimeAfterStartTime",
                }),
                test: function (value) {
                  if (autoEndTime) {
                    return true;
                  }
                  if (!value) {
                    return true;
                  }

                  const dateTimeStart = mergeDateTime(this.parent.startDate, this.parent.startTime);

                  const dateTimeEnd = mergeDateTime(this.parent.stopDate, value);

                  return dateTimeEnd > dateTimeStart;
                },
              }),
          }),
        })
      ),
    actionId1: yup.string().required(intl.formatMessage({ id: "visit.actionRequired" })),
    solutionDescription: yup.string(),
  });
};
