import {
  Action,
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { getChecklistStatus, isEmpty, toDateString } from "helpers";
import { jobsDictionaryToArray } from "helpers/jobsDictionaryToArray";
import { cloneDeep, isEqual } from "lodash";
import { CustomerSimple } from "models/CustomerSimple";
import { EngineerSignoffValidationSchema } from "models/EngineerSignoffValidationSchema";
import { Job, Jobs } from "models/Job";
import { defaultJobFilter, JobFilter, JobFilterKeys } from "models/JobFilter";
import { initialVisitFormValues } from "models/jobVisitForm";
import { JobVisitType } from "models/JobVisitType";
import { Times } from "models/travelTimes";
import { TravelValidationSchema } from "models/TravelValidationSchema";
import { UsedPart } from "models/usedPart";
import { VisitValidationSchema } from "models/VisitValidationSchema";
import {
  ChecklistGroupsType,
  ChecklistType,
  EquipmentType,
  ExtraInputType,
  FileType,
  JobCategoryType,
  JobStatus,
  MeterReadingInputType,
  PartStatus,
  QuestionDataType,
  StockStore,
  TimesType,
  WorkNoteType,
  WorkNoteTypeEnum,
} from "operations/schema/schema";
import { RootState } from "store";
import { ValidationError } from "yup";
import { asyncMutations, mutationBuilder } from "./jobs.mutations";
import { asyncQueries, queryBuilder } from "./jobs.queries";

// Interface for state
export interface State {
  jobs: Jobs;
  jobVisits: {
    [x: string]: JobVisitType;
  };
  selectedJobId: string | undefined;
  jobTab: string;
  lastLoaded: string | null;
  jobFilter: JobFilter;
  loadingJob?: boolean;
  loadingJobs?: boolean;
  loadingRelatedJobs?: boolean;
  newJobIds: string[];
  hideNewJobNotification: boolean;
  filterOptions: {
    customers: CustomerSimple[];
    loadingCustomers: boolean;
  };
  changeJobEquipment: {
    equipments: EquipmentType[];
    loading: boolean;
    loadingEquipments: boolean;
    open: boolean;
  };
  rejectJob: {
    open: boolean;
    loading: boolean;
    text: string;
  };
  updateEquipment: {
    open: boolean;
    loading: boolean;
  };
  editContact: {
    open: boolean;
    loading: boolean;
  };
  updatePlannedDate: {
    open: boolean;
    loading: boolean;
  };
  machineProperties: {
    open: boolean;
    loading: boolean;
  };
  contactsLoading: boolean;
  addChecklist: {
    open: boolean;
    openSelect: boolean;
    loading: boolean;
    useEquipment: boolean;
    addLoading: boolean;
    checklists: ChecklistGroupsType[];
  };
  unusedPreOrderedParts: {
    open: boolean;
  };
}

// Interface for store actions
interface Actions extends SliceCaseReducers<State> {
  setJobTab: (state: State, action: PayloadAction<{ tab: string }>) => State;
  handleCloseRejectJob: (state: State, action: Action) => State;
  handleCloseUpdateEquipment: (state: State, action: Action) => State;
  handleCloseUpdatePlannedDate: (state: State, action: Action) => State;
  resetJobFilter: (state: State, action: Action) => State;
  resetJobStatus: (state: State, action: PayloadAction<{ jobId: string }>) => State;
  setJobFilter: (
    state: State,
    action: PayloadAction<{ jobFilter: JobFilter; hideNewJobNotification: boolean }>
  ) => State;
  setJobStatus: (
    state: State,
    action: PayloadAction<{ jobId: string; status: JobStatus }>
  ) => State;
  setPlannedDate: (
    state: State,
    action: PayloadAction<{ jobId: string; times: TimesType }>
  ) => State;
  setRejectJobOpen: (state: State, action: PayloadAction<{ open: boolean }>) => State;
  setRejectJobText: (state: State, action: PayloadAction<{ text: string }>) => State;
  setChangeJobEquipmentOpen: (state: State, action: PayloadAction<{ open: boolean }>) => State;
  setUpdateEquipmentOpen: (state: State, action: PayloadAction<{ open: boolean }>) => State;
  setEditContactOpen: (state: State, action: PayloadAction<{ open: boolean }>) => State;
  setUpdatePlannedDateOpen: (state: State, action: PayloadAction<{ open: boolean }>) => State;
  clearNewJobIds: (state: State, action: Action) => State;
  filterNewJobIds: (state: State, action: PayloadAction<{ jobId: string }>) => State;
  setViewUnusedPreOrderedPartsOpen: (
    state: State,
    action: PayloadAction<{ open: boolean }>
  ) => State;
  initializeVisit: (
    state: State,
    action: PayloadAction<{
      job: Job;
      jobCategories: JobCategoryType[];
    }>
  ) => State;
  setVisitCompleted: (state: State, action: Action) => State;
  setTravelTimes: (state: State, action: PayloadAction<{ times: Times[] }>) => State;
  setSelectedJob: (state: State, action: PayloadAction<{ jobId: string | undefined }>) => State;
  setAutoEndTime: (state: State, action: PayloadAction<{ autoEndTime: boolean }>) => State;
  // Form
  setVisitValue: <K extends Extract<keyof JobVisitType, string>>(
    state: State,
    action: PayloadAction<{ key: K; value: JobVisitType[K]; shouldValidate?: boolean }>
  ) => State;
  validateVisit: (state: State, action: Action) => State;
  // Checklists
  updateChecklist: (
    state: State,
    action: PayloadAction<{ checklist: ChecklistType; index: number }>
  ) => State;
  uploadedChecklists: (state: State, action: Action) => State;
  // Files
  addFile: (state: State, action: PayloadAction<{ file: FileType }>) => State;
  removeFile: (state: State, action: PayloadAction<{ id: string }>) => State;
  updateFile: (state: State, action: PayloadAction<{ file: FileType }>) => State;
  uploadedFiles: (state: State, action: Action) => State;
  // MeterReadings
  updateMeterReadings: (
    state: State,
    action: PayloadAction<{ readings: MeterReadingInputType[] }>
  ) => State;
  setMetersNotSaved: (state: State, action: PayloadAction<{ unSavedChanges: boolean }>) => State;
  setMetersUseCurrent: (state: State, action: PayloadAction<{ useCurrent: boolean }>) => State;
  // Extras
  addExtra: (state: State, action: PayloadAction<{ extra: ExtraInputType }>) => State;
  removeExtra: (state: State, action: PayloadAction<{ index: number }>) => State;
  updateExtra: (state: State, action: PayloadAction<{ extra: ExtraInputType }>) => State;
  // Parts
  addPart: (state: State, action: PayloadAction<{ part: UsedPart }>) => State;
  addParts: (state: State, action: PayloadAction<{ parts: UsedPart[] }>) => State;
  addPartsMany: (
    state: State,
    action: PayloadAction<{ jobsWithAddedParts: { id: string; parts: UsedPart[] }[] }>
  ) => State;
  removePart: (
    state: State,
    action: PayloadAction<{ id: string; stockStore: StockStore }>
  ) => State;
  updatePart: (state: State, action: PayloadAction<{ part: UsedPart }>) => State;
  uploadedAddedParts: (state: State, action: PayloadAction<{ addedParts: string[] }>) => State;
  uploadedRequestedParts: (state: State, action: Action) => State;
  // Signature
  setSignatureCustomer: (
    state: State,
    action: PayloadAction<{ signatureData: string | undefined }>
  ) => State;
  setSignatureEngineer: (
    state: State,
    action: PayloadAction<{ signatureData: string | undefined }>
  ) => State;
  addTravel: (state: State) => State;
  stopTravel: (state: State) => State;
  //EquipmentPropsDialog
  setMachinePropertiesOpen: (state: State, actions: PayloadAction<{ open: boolean }>) => State;
  handleCloseAddChecklist: (state: State, action: Action) => State;
  handleCloseAddChecklistSelect: (state: State, action: Action) => State;
  setAddChecklistOpen: (state: State, action: PayloadAction<{ open: boolean }>) => State;
  setAddChecklistSelectOpen: (
    state: State,
    action: PayloadAction<{ open: boolean; useEquipment: boolean }>
  ) => State;
}

// Interface for store selectors (if necessary)
interface Selectors {
  selectJobLoaded: (state: RootState) => boolean;
  selectJob: (state: RootState, id: string | undefined) => Job | undefined;
  selectJobs: (state: RootState) => Job[];
  selectIncompleteJobs: (state: RootState) => Job[];
  selectLoadingJobs: (state: RootState) => boolean;
  selectJobFilterCount: (state: RootState) => number;
  selectNewJobIds: (state: RootState) => string[];
  selectUnusedPreOrderedPartsCount: (state: RootState) => number;
  selectJobsWithPreOrderedParts: (state: RootState) => Job[];
  selectVisitLoaded: (state: RootState) => boolean;
  selectTravelTimes: (state: RootState) => Times[];
  selectSelectedJob: (state: RootState) => Job;
  selectSelectedJobVisit: (state: RootState) => JobVisitType;
  selectWorkNotes: (state: RootState) => WorkNoteType[];
  selectVisitSelectedJobId: (state: RootState) => string | undefined;
  selectTravelTabInvalid: (state: RootState) => boolean;
  selectChecklistsIncomplete: (state: RootState) => boolean;
  selectVisitCanComplete: (state: RootState) => boolean;
  selectIsJobInProgress: (state: RootState) => boolean;
  selectVisitStarted: (state: RootState) => boolean;
  selectContactsLoading: (state: RootState) => boolean;
}

// Definition of actual (initial) state
export const initialState: State = {
  jobs: {},
  jobVisits: {},
  selectedJobId: undefined,
  jobTab: "details",
  lastLoaded: null,
  newJobIds: [],
  hideNewJobNotification: false,
  jobFilter: {
    ...defaultJobFilter,
  },
  filterOptions: {
    customers: [],
    loadingCustomers: false,
  },
  changeJobEquipment: {
    equipments: [],
    loading: false,
    loadingEquipments: false,
    open: false,
  },
  rejectJob: {
    open: false,
    loading: false,
    text: "",
  },
  updateEquipment: {
    open: false,
    loading: false,
  },
  editContact: {
    open: false,
    loading: false,
  },
  updatePlannedDate: {
    open: false,
    loading: false,
  },
  machineProperties: {
    open: false,
    loading: false,
  },
  contactsLoading: false,
  addChecklist: {
    open: false,
    openSelect: false,
    loading: false,
    useEquipment: false,
    addLoading: false,
    checklists: [],
  },
  unusedPreOrderedParts: {
    open: false,
  },
};

// Definition of actual actions
const actions: Actions = {
  setJobTab(state, { payload: { tab } }) {
    state.jobTab = tab;
    return state;
  },
  setRejectJobOpen: (state, { payload: { open } }) => {
    state.rejectJob.open = open;
    return state;
  },
  setRejectJobText: (state, { payload: { text } }) => {
    state.rejectJob.text = text;
    return state;
  },
  handleCloseRejectJob: (state) => {
    state.rejectJob.text = "";
    state.rejectJob.open = false;
    return state;
  },
  setChangeJobEquipmentOpen: (state, { payload: { open } }) => {
    state.changeJobEquipment.open = open;
    return state;
  },
  setUpdateEquipmentOpen: (state, { payload: { open } }) => {
    state.updateEquipment.open = open;
    return state;
  },
  handleCloseUpdateEquipment: (state) => {
    state.updateEquipment.open = false;
    return state;
  },
  setUpdatePlannedDateOpen: (state, { payload: { open } }) => {
    state.updatePlannedDate.open = open;
    return state;
  },
  handleCloseUpdatePlannedDate: (state) => {
    state.updatePlannedDate.open = false;
    return state;
  },
  setPlannedDate: (state, { payload: { jobId, times } }) => {
    state.jobs[jobId].plannedDate = times;
    return state;
  },
  setJobFilter(state, { payload: { jobFilter, hideNewJobNotification } }) {
    state.jobFilter = jobFilter;
    state.hideNewJobNotification = hideNewJobNotification;
    return state;
  },
  resetJobFilter(state) {
    state.jobFilter = { ...defaultJobFilter };
    state.hideNewJobNotification = true;
    return state;
  },
  setJobStatus: (state, { payload: { jobId, status } }) => {
    if (!state.jobs[jobId] || state.jobs[jobId].status === status) return state;
    state.jobs[jobId].previousStatus = state.jobs[jobId].status || undefined;
    state.jobs[jobId].status = status;
    return state;
  },
  resetJobStatus: (state, { payload: { jobId } }) => {
    if (!state.jobs[jobId] || !state.jobs[jobId].previousStatus) return state;
    state.jobs[jobId].status = state.jobs[jobId].previousStatus;
    state.jobs[jobId].previousStatus = undefined;
    return state;
  },
  setEditContactOpen: (state, { payload: { open } }) => {
    state.editContact.open = open;
    return state;
  },
  clearNewJobIds: (state) => {
    state.newJobIds = [];
    return state;
  },
  filterNewJobIds: (state, { payload: { jobId } }) => {
    state.newJobIds = state.newJobIds.filter((id) => id !== jobId);
    return state;
  },
  setViewUnusedPreOrderedPartsOpen(state, { payload: { open } }) {
    state.unusedPreOrderedParts.open = open;
    return state;
  },
  initializeVisit: (state, { payload: { job, jobCategories } }) => {
    let { selectedJobId, jobVisits } = state;
    if (selectedJobId && !jobVisits[selectedJobId]) {
      const travelTimes = job.travelTimes.filter((tt) => tt.startTime || tt.stopTime);

      state.jobVisits[selectedJobId] = {
        checklists: [
          ...job.checklists.map((cl) => ({
            checklist: cl,
            uploaded: false,
            hasPreStart: cl.questions.some((q) => q.isRequiredPreStart),
          })),
        ],
        files: [...job.files],
        extras: [],
        meterReadings: [
          ...job.meters.map(
            (mr) =>
              ({
                currentReading: undefined,
                currentReadingDate: undefined,
                typeId: mr.typeId,
              } as MeterReadingInputType)
          ),
        ],
        usedParts: [], // TODO; Initialize
        travelTimes: (travelTimes || []).map((tt) => ({
          startDate: toDateString(tt.startTime!),
          startTime: toDateString(tt.startTime!),
          stopDate: toDateString(tt.stopTime) || undefined,
          stopTime: toDateString(tt.stopTime) || undefined,
        })),
        followUp: (() => {
          let bool = !!job.preOrderedParts.length;
          return {
            followUpChecked: bool,
          };
        })(),
        signatureCustomer: undefined,
        signatureEngineer: undefined,
        newWorkUnitId: undefined,
        autoEndTime: true,
        ...initialVisitFormValues(job, jobCategories),
        errors: {},
        signoffErrors: {},
        submitLoading: false,
        metersNotSaved: job.requireMeterReading === true && job.meters.length > 0,
        metersUseCurrent: false,
        hasCategories: !!jobCategories.length,
      };
    }
    return state;
  },
  setVisitCompleted: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    // TODO; Merge data into jobs.store
    delete jobVisits[selectedJobId];
    return state;
  },
  setTravelTimes: (state, { payload: { times } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId) return state;
    let visit = jobVisits[selectedJobId];
    jobVisits[selectedJobId].travelTimes = [...times];
    const validationSchema = TravelValidationSchema();
    const isValid = validationSchema.isValidSync(visit);
    if (!isValid) {
      try {
        validationSchema.validateSync({ ...visit }, { strict: true, abortEarly: false });
      } catch (e: any) {
        let { inner } = e as ValidationError;
        let errors: any = {};
        for (let error of inner) {
          if (!error.path) continue;
          errors[error.path] = error.message;
        }
        visit.errors = { ...errors };
      }
    } else {
      visit.errors = {};
    }
    return state;
  },
  setSelectedJob: (state, { payload: { jobId } }) => {
    state.selectedJobId = jobId;
    return state;
  },
  setAutoEndTime: (state, { payload: { autoEndTime } }) => {
    if (!state.selectedJobId) return state;
    state.jobVisits[state.selectedJobId].autoEndTime = autoEndTime;
    return state;
  },

  // Form
  setVisitValue: (state, { payload: { key, value, shouldValidate = true } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const visit = jobVisits[selectedJobId];
    // const visitForm = visit.form;
    visit[key] = value;
    if (shouldValidate) {
      const validationSchema = VisitValidationSchema(visit.autoEndTime);
      const isValid = validationSchema.isValidSync(visit);
      if (!isValid) {
        try {
          validationSchema.validateSync({ ...visit }, { strict: true, abortEarly: false });
        } catch (e: any) {
          let { inner } = e as ValidationError;
          let errors: any = {};
          for (let error of inner) {
            if (!error.path) continue;
            errors[error.path] = error.message;
          }
          visit.errors = { ...errors };
        }
      } else {
        visit.errors = {};
      }

      const signoffValidation = EngineerSignoffValidationSchema(
        visit.hasCategories,
        visit.followUp.followUpChecked
      );
      const isSignoffValid = signoffValidation.isValidSync(visit);
      if (!isSignoffValid) {
        try {
          signoffValidation.validateSync({ ...visit }, { strict: true, abortEarly: false });
        } catch (e: any) {
          let { inner } = e as ValidationError;
          let errors: any = {};
          for (let error of inner) {
            if (!error.path) continue;
            errors[error.path] = error.message;
          }
          visit.signoffErrors = { ...errors };
        }
      } else {
        visit.signoffErrors = {};
      }
    }
    return state;
  },
  validateVisit: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const visit = jobVisits[selectedJobId];
    // const visitForm = visit.form;
    const validationSchema = VisitValidationSchema(visit.autoEndTime);
    const isValid = validationSchema.isValidSync(visit);
    if (!isValid) {
      try {
        validationSchema.validateSync({ ...visit }, { strict: true, abortEarly: false });
      } catch (e: any) {
        let { inner } = e as ValidationError;
        let errors: any = {};
        for (let error of inner) {
          if (!error.path) continue;
          errors[error.path] = error.message;
        }
        visit.errors = { ...errors };
      }
    } else {
      visit.errors = {};
    }

    const signoffValidation = EngineerSignoffValidationSchema(
      visit.hasCategories,
      visit.followUp.followUpChecked
    );
    const isSignoffValid = signoffValidation.isValidSync(visit);
    if (!isSignoffValid) {
      try {
        signoffValidation.validateSync({ ...visit }, { strict: true, abortEarly: false });
      } catch (e: any) {
        let { inner } = e as ValidationError;
        let errors: any = {};
        for (let error of inner) {
          if (!error.path) continue;
          errors[error.path] = error.message;
        }
        visit.signoffErrors = { ...errors };
      }
    } else {
      visit.signoffErrors = {};
    }
    // state.jobVisits[selectedJobId].form = visitForm;
    // yup.validate();
    return state;
  },
  // Checklists
  updateChecklist: (state, { payload: { checklist, index } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { checklists } = jobVisits[selectedJobId];
    checklist.questions = checklist.questions.map((q) => {
      if (
        q.dataType === QuestionDataType.DateTime ||
        q.dataType === QuestionDataType.Date ||
        q.dataType === QuestionDataType.TimeOfDay
      ) {
        q.answer = toDateString(q.answer);
      }
      return q;
    });
    checklists[index] = {
      uploaded: false,
      checklist,
      hasPreStart: checklist.questions.some((q) => q.isRequiredPreStart),
    };
    jobVisits[selectedJobId].checklists = [...checklists];
    return state;
  },
  uploadedChecklists: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const { checklists } = jobVisits[selectedJobId];
    checklists.map((cl) => (cl.uploaded = true));
    jobVisits[selectedJobId].checklists = [...checklists];
    return state;
  },
  // Files
  addFile: (state, { payload: { file } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { files } = jobVisits[selectedJobId];
    jobVisits[selectedJobId].files = [file, ...files];
    return state;
  },
  removeFile: (state, { payload: { id } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { files } = jobVisits[selectedJobId];
    files = files.filter((f) => f.id !== id);
    jobVisits[selectedJobId].files = [...files];
    return state;
  },
  updateFile: (state, { payload: { file } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { files } = jobVisits[selectedJobId];
    let index = files.findIndex((f) => f.id === file.id);
    files[index] = file;
    jobVisits[selectedJobId].files = [...files];
    return state;
  },
  uploadedFiles: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    jobVisits[selectedJobId].files = [];
    return state;
  },
  // MeterReadings
  updateMeterReadings: (state, { payload: { readings } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    jobVisits[selectedJobId].meterReadings = [...readings];
    return state;
  },
  setMetersNotSaved: (state, { payload: { unSavedChanges } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    jobVisits[selectedJobId].metersNotSaved = unSavedChanges;
    return state;
  },
  setMetersUseCurrent: (state, { payload: { useCurrent } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    jobVisits[selectedJobId].metersUseCurrent = useCurrent;
    return state;
  },
  // Extras
  addExtra: (state, { payload: { extra } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { extras } = jobVisits[selectedJobId];
    jobVisits[selectedJobId].extras = [extra, ...extras];
    return state;
  },
  removeExtra: (state, { payload: { index } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { extras } = jobVisits[selectedJobId];
    extras.splice(index, 1);
    jobVisits[selectedJobId].extras = [...extras];
    return state;
  },
  updateExtra: (state, { payload: { extra } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { extras } = jobVisits[selectedJobId];
    let index = extras.findIndex((f) => f.id === extra.id);
    extras[index] = extra;
    jobVisits[selectedJobId].extras = [...extras];
    return state;
  },
  // Parts
  addPart: (state, { payload: { part } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { usedParts } = jobVisits[selectedJobId];
    jobVisits[selectedJobId].usedParts = [part, ...usedParts];
    if (part.part.status === PartStatus.Requested) {
      jobVisits[selectedJobId].followUp = {
        ...jobVisits[selectedJobId].followUp,
        followUpChecked: true,
      };
    } else if (!usedParts.some((part) => part.part.status === PartStatus.Requested)) {
      jobVisits[selectedJobId].followUp = {
        ...jobVisits[selectedJobId].followUp,
        followUpChecked: false,
      };
    }
    return state;
  },
  addParts: (state, { payload: { parts } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { usedParts } = jobVisits[selectedJobId];
    jobVisits[selectedJobId].usedParts = [...parts, ...usedParts];
    if (parts.some((part) => part.part.status === PartStatus.Requested)) {
      jobVisits[selectedJobId].followUp = {
        ...jobVisits[selectedJobId].followUp,
        followUpChecked: true,
      };
    } else if (!usedParts.some((part) => part.part.status === PartStatus.Requested)) {
      jobVisits[selectedJobId].followUp = {
        ...jobVisits[selectedJobId].followUp,
        followUpChecked: false,
      };
    }
    return state;
  },
  addPartsMany: (state, { payload: { jobsWithAddedParts } }) => {
    const { jobVisits } = state;

    jobsWithAddedParts.forEach((job) => {
      if (jobVisits[job.id]) {
        let { usedParts } = jobVisits[job.id];
        jobVisits[job.id].usedParts = [...job.parts, ...usedParts];
        if (job.parts.some((part) => part.part.status === PartStatus.Requested)) {
          jobVisits[job.id].followUp = {
            ...jobVisits[job.id].followUp,
            followUpChecked: true,
          };
        } else if (!usedParts.some((part) => part.part.status === PartStatus.Requested)) {
          jobVisits[job.id].followUp = {
            ...jobVisits[job.id].followUp,
            followUpChecked: false,
          };
        }
      }
    });

    return state;
  },
  removePart: (state, { payload: { id, stockStore } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { usedParts } = jobVisits[selectedJobId];
    usedParts = usedParts.filter((p) => !(p.part.id === id && p.part.stockStore === stockStore));
    jobVisits[selectedJobId].usedParts = [...usedParts];
    if (!usedParts.some((part) => part.part.status === PartStatus.Requested)) {
      jobVisits[selectedJobId].followUp = {
        ...jobVisits[selectedJobId].followUp,
        followUpChecked: false,
      };
    } else if (usedParts.some((part) => part.part.status === PartStatus.Requested)) {
      jobVisits[selectedJobId].followUp = {
        ...jobVisits[selectedJobId].followUp,
        followUpChecked: true,
      };
    }
    return state;
  },
  updatePart: (state, { payload: { part } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { usedParts } = jobVisits[selectedJobId];
    let index = usedParts.findIndex((p) => p.part.id === part.part.id);
    usedParts[index] = part;
    jobVisits[selectedJobId].usedParts = [...usedParts];
    return state;
  },
  uploadedAddedParts: (state, { payload: { addedParts } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const { usedParts } = jobVisits[selectedJobId];
    usedParts.map((p) =>
      p.part.stockStore !== StockStore.Other && addedParts.includes(p.part.id!)
        ? (p.uploaded = true)
        : p
    );
    jobVisits[selectedJobId].usedParts = [...usedParts];
    return state;
  },
  uploadedRequestedParts: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const { usedParts } = jobVisits[selectedJobId];
    usedParts.map((p) => (p.part.stockStore === StockStore.Other ? (p.uploaded = true) : p));
    jobVisits[selectedJobId].usedParts = [...usedParts];
    return state;
  },
  // Signature
  setSignatureCustomer: (state, { payload: { signatureData } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    state.jobVisits[selectedJobId].signatureCustomer = signatureData;
    return state;
  },
  setSignatureEngineer: (state, { payload: { signatureData } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    state.jobVisits[selectedJobId].signatureEngineer = signatureData;
    return state;
  },
  addTravel: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const newTravelTime = {
      startDate: toDateString(Date.now()),
      startTime: toDateString(Date.now()),
    };
    if (jobVisits[selectedJobId].travelTimes.length === 0) {
      jobVisits[selectedJobId].travelTimes = cloneDeep([newTravelTime]);
    } else {
      jobVisits[selectedJobId].travelTimes = [
        ...jobVisits[selectedJobId].travelTimes,
        newTravelTime,
      ];
    }

    return state;
  },
  stopTravel: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const lastIndex = jobVisits[selectedJobId].travelTimes.length - 1;
    if (lastIndex !== -1) {
      jobVisits[selectedJobId].travelTimes[lastIndex].stopDate = toDateString(Date.now());
      jobVisits[selectedJobId].travelTimes[lastIndex].stopTime = toDateString(Date.now());
    }
    return state;
  },
  //EquipmentPropsDialog
  setMachinePropertiesOpen: (state, { payload: { open } }) => {
    state.machineProperties.open = open;
    return state;
  },
  // AddChecklistDialog
  handleCloseAddChecklist: (state) => {
    state.addChecklist.open = false;
    return state;
  },
  handleCloseAddChecklistSelect: (state) => {
    state.addChecklist.openSelect = false;
    return state;
  },
  setAddChecklistOpen: (state, { payload: { open } }) => {
    state.addChecklist.open = open;
    return state;
  },
  setAddChecklistSelectOpen: (state, { payload: { open, useEquipment } }) => {
    state.addChecklist.openSelect = open;
    state.addChecklist.useEquipment = useEquipment;
    return state;
  },
};

// Definition of actual selectors
const selectors: Selectors = {
  selectJobLoaded({ jobs: { jobs }, root: { selectedJobId } }) {
    if (selectedJobId && jobs[selectedJobId]) {
      return true;
    }
    return false;
  },
  selectJob({ jobs }, id) {
    return id ? jobs.jobs[id] : undefined;
  },
  selectJobs({ jobs }) {
    return jobsDictionaryToArray(jobs.jobs);
  },
  selectIncompleteJobs({ jobs }) {
    const jobList = jobsDictionaryToArray(jobs.jobs).filter(
      (j) => j.status !== JobStatus.Completed && j.status !== JobStatus.Rejected
    );
    return jobList;
  },
  selectLoadingJobs({ jobs: { loadingJobs } }) {
    return !!loadingJobs;
  },
  selectJobFilterCount: ({ jobs: { jobFilter } }) => {
    let count = 1; // Start at 1 as we always date filter
    for (const k of JobFilterKeys) {
      if (isEqual(defaultJobFilter[k], jobFilter[k]) || isEmpty(jobFilter[k])) continue;
      count++;
    }
    return count;
  },
  selectNewJobIds: ({ jobs }) => {
    return jobs.newJobIds;
  },
  selectUnusedPreOrderedPartsCount({ jobs: { jobs, jobVisits } }) {
    let count = 0;
    const jobList = jobsDictionaryToArray(jobs);
    jobList.forEach((job) => {
      if (job.preOrderedParts.length === 0) return;
      const visit = jobVisits[job.id];
      let parts = visit.usedParts.filter((p) => p && p?.part.stockStore !== StockStore.Other);
      var usedPreordered = job.preOrderedParts.filter((pop) =>
        parts.some((up) => up?.part.id === pop?.id && up?.part.partNumber === pop?.partNumber)
      );
      count += job.preOrderedParts.length - usedPreordered.length;
    });
    return count;
  },
  selectJobsWithPreOrderedParts({ jobs: { jobs, jobVisits } }) {
    const jobList = jobsDictionaryToArray(jobs);
    return jobList.filter((job) => {
      if (job.preOrderedParts.length === 0) return false;
      const visit = jobVisits[job.id];
      let parts = visit.usedParts.filter((p) => p && p?.part.stockStore !== StockStore.Other);
      var usedPreordered = job.preOrderedParts.filter((pop) =>
        parts.some((up) => up?.part.id === pop?.id && up?.part.partNumber === pop?.partNumber)
      );
      return usedPreordered.length < job.preOrderedParts.length;
    });
  },
  selectVisitLoaded({ jobs: { selectedJobId, jobVisits } }) {
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      return false;
    }
    return true;
  },
  selectTravelTimes({ jobs: { jobs, jobVisits, selectedJobId } }) {
    if (!selectedJobId || !jobs[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    return jobVisits[selectedJobId].travelTimes;
  },
  selectSelectedJob({ jobs: { jobs, selectedJobId } }) {
    if (!selectedJobId || !jobs[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    return jobs[selectedJobId];
  },
  selectSelectedJobVisit({ jobs: { selectedJobId, jobVisits } }) {
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    return jobVisits[selectedJobId];
  },
  selectWorkNotes({ jobs: { jobs, selectedJobId } }) {
    if (!selectedJobId || !jobs[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    return jobs[selectedJobId].workNotes.filter((wn) => wn.type === WorkNoteTypeEnum.Ticket);
  },
  selectVisitSelectedJobId({ jobs: { selectedJobId } }) {
    return selectedJobId;
  },
  selectTravelTabInvalid: ({ jobs: { selectedJobId, jobVisits } }) => {
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    const visit = jobVisits[selectedJobId];

    // Actual validation issues will be caught via visit.errors check
    // but unmatched pairs do not validate correctly
    let travelHasMatchingStopTimes = true;
    if (visit.travelTimes.length > 0) {
      travelHasMatchingStopTimes = visit.travelTimes.every(({ stopDate, stopTime }) => {
        return !!stopDate && !!stopTime;
      });
    }
    return !travelHasMatchingStopTimes;
  },
  selectChecklistsIncomplete: ({ jobs: { selectedJobId, jobVisits } }) => {
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    const visit = jobVisits[selectedJobId];

    let checklistsComplete = true;
    if (visit.checklists.length > 0) {
      checklistsComplete = visit.checklists.every(({ checklist }) => {
        const { isComplete } = getChecklistStatus(checklist);

        return isComplete;
      });
    }

    return !checklistsComplete;
  },
  selectVisitCanComplete: ({ jobs: { selectedJobId, jobVisits } }) => {
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    const visit = jobVisits[selectedJobId];
    const isValid = !Object.keys(visit.errors).length;
    const hasAction = (visit.actionId1?.length || 0) > 0;
    const meterReadingsComplete = !visit.metersNotSaved;

    let checklistsComplete = true;
    if (visit.checklists.length > 0) {
      checklistsComplete = visit.checklists.every(({ checklist }) => {
        const { isComplete } = getChecklistStatus(checklist);

        return isComplete;
      });
    }

    // Actual validation issues will be caught via visit.errors check
    // but unmatched pairs do not validate correctly
    let travelHasMatchingStopTimes = true;
    if (visit.travelTimes.length > 0) {
      travelHasMatchingStopTimes = visit.travelTimes.every(({ stopDate, stopTime }) => {
        return !!stopDate && !!stopTime;
      });
    }
    return (
      isValid &&
      hasAction &&
      checklistsComplete &&
      meterReadingsComplete &&
      travelHasMatchingStopTimes
    );
  },
  selectIsJobInProgress: ({ jobs: { jobs, selectedJobId, jobVisits } }) => {
    if (!selectedJobId || !jobs[selectedJobId] || !jobVisits[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    const job = jobs[selectedJobId];
    const visit = jobVisits[selectedJobId];

    return (
      job.status === JobStatus.InProgress ||
      (!isEmpty(visit.workTimes) && visit.workTimes[0].startTime !== null) ||
      (!isEmpty(visit.travelTimes) && visit.travelTimes[0].startTime != null)
    );
  },
  selectVisitStarted: ({ jobs: { jobs, selectedJobId, jobVisits } }) => {
    if (!selectedJobId || !jobs[selectedJobId] || !jobVisits[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    const job = jobs[selectedJobId];
    const visit = jobVisits[selectedJobId];

    return (
      job.status === JobStatus.InProgress ||
      (!isEmpty(visit.workTimes) && visit.workTimes[0].startTime !== null)
    );
  },
  selectContactsLoading({ jobs: { contactsLoading } }) {
    return contactsLoading;
  },
};

// * job: Name of store with lowercase letters
export const storeBase = createSlice<State, Actions>({
  name: "jobs",
  initialState,
  reducers: actions,
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    queryBuilder(builder);
    mutationBuilder(builder);
  },
});

// To be imported and added in store/reducers
export default storeBase.reducer;
export const {
  setJobTab,
  handleCloseRejectJob,
  handleCloseUpdateEquipment,
  handleCloseUpdatePlannedDate,
  resetJobFilter,
  resetJobStatus,
  setJobFilter,
  setJobStatus,
  setPlannedDate,
  setRejectJobOpen,
  setRejectJobText,
  setChangeJobEquipmentOpen,
  setUpdateEquipmentOpen,
  setEditContactOpen,
  setUpdatePlannedDateOpen,
  clearNewJobIds,
  filterNewJobIds,
  setViewUnusedPreOrderedPartsOpen,
  addExtra,
  addFile,
  addPart,
  addParts,
  addPartsMany,
  initializeVisit,
  removeExtra,
  removeFile,
  removePart,
  setSelectedJob,
  setTravelTimes,
  setVisitCompleted,
  setVisitValue,
  updateChecklist,
  updateExtra,
  updateFile,
  updateMeterReadings,
  setMetersNotSaved,
  setMetersUseCurrent,
  updatePart,
  uploadedAddedParts,
  uploadedChecklists,
  uploadedExtras,
  uploadedFiles,
  uploadedRequestedParts,
  validateVisit,
  setSignatureCustomer,
  setSignatureEngineer,
  setAutoEndTime,
  addTravel,
  stopTravel,
  setMachinePropertiesOpen,
  handleCloseAddChecklist,
  handleCloseAddChecklistSelect,
  setAddChecklistOpen,
  setAddChecklistSelectOpen,
} = storeBase.actions;
export const {
  selectJobLoaded,
  selectJob,
  selectJobs,
  selectIncompleteJobs,
  selectLoadingJobs,
  selectJobFilterCount,
  selectNewJobIds,
  selectUnusedPreOrderedPartsCount,
  selectJobsWithPreOrderedParts,
  selectVisitLoaded,
  selectTravelTimes,
  selectSelectedJob,
  selectSelectedJobVisit,
  selectWorkNotes,
  selectVisitSelectedJobId,
  selectTravelTabInvalid,
  selectChecklistsIncomplete,
  selectVisitCanComplete,
  selectIsJobInProgress,
  selectVisitStarted,
  selectContactsLoading,
} = selectors;
export const {
  getJob,
  getJobs,
  getCustomers,
  getFiles,
  getNotes,
  getRelatedJobs,
  getVisits,
  getEquipment,
  getChecklistGroups,
  getContacts,
} = asyncQueries;

export const {
  rejectJob,
  acceptJob,
  updateEquipment,
  updatePlannedDate,
  changeJobEquipment,
  editContact,
  completeVisit,
  startVisitTravel,
  startVisitWork,
  updateVisitTravel,
  updateMachineCustomProps,
  updateMeters,
  addChecklist,
} = asyncMutations;
