import { nanoid } from "nanoid";
import { FC, useEffect, useState } from "react";
import Resizer from "react-image-file-resizer";
import { FormattedMessage, useIntl } from "react-intl";

import { NoteAdd as NoteAddIcon } from "@mui/icons-material";
import WarningIcon from "@mui/icons-material/Warning";
import { Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { AsolviSwitch } from "components/AsolviSwitch";
import { FilePreview } from "components/FilePreview";
import FullScreenDialog from "components/FullScreenDialog";
import { PromptDialog } from "components/PromptDialog";
import { StyledFab } from "components/StyledFab";
import { EditPhoto } from "components/editPhoto/EditPhoto";

import { FileType } from "operations/schema/schema";
import { useAppDispatch, useAppSelector } from "store";
import {
  addFile,
  removeFile,
  selectSelectedJob,
  selectSelectedJobVisit,
  updateFile,
} from "store/slices/jobs.store";
import { TabProps } from "./TabProps";

const PREFIX = "FileTab";

const classes = {
  input: `${PREFIX}-input`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2),

  [`& .${classes.input}`]: {
    display: "none",
  },
}));

export const FileTab: FC<TabProps> = (props) => {
  const { setFab } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { files } = useAppSelector(selectSelectedJobVisit);
  const { externalId } = useAppSelector(selectSelectedJob);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [removeId, setRemoveId] = useState<string | undefined>(undefined);
  const [openCloseConfirmation, setOpenCloseConfirmation] = useState(false);
  const [isFullSize, setIsFullSize] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState<FileType | undefined>();

  const resizeFile = (file: Blob) =>
    new Promise((resolve) => {
      if (file.type.includes("image/") && !isFullSize) {
        Resizer.imageFileResizer(
          file,
          600,
          600,
          "JPG",
          80,
          0,
          (uri) => {
            resolve(uri);
          },
          "base64"
        );
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(file);
      }
    });

  const handleFileInput = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      resizeFile(file).then((data) => {
        const output = typeof data === "string" ? data : "";
        const f: FileType = {
          id: nanoid(),
          name: file.name,
          data: output,
          description: "",
          size: output.length,
          isFullSize: isFullSize,
        };
        dispatch(addFile({ file: f }));
      });
    }
    e.target.value = null;
  };

  useEffect(() => {
    const fab = (
      <label htmlFor="upload-button-file">
        <StyledFab color="primary" component="span">
          <NoteAddIcon />
        </StyledFab>
      </label>
    );
    if (setFab) {
      setFab(fab);
    }
  }, [setFab]);

  return (
    <StyledContainer data-testid="FileTab">
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ paddingTop: "20px", paddingBottom: "20px" }}
      >
        <Grid item>
          <Typography
            variant="body1"
            onClick={() => {
              setIsFullSize(!isFullSize);
            }}
          >
            <FormattedMessage id="file.enableTextCapture" />
          </Typography>
        </Grid>
        <Grid item>
          <AsolviSwitch
            key="FullSizeSwitch"
            checked={isFullSize}
            onChange={() => {
              setIsFullSize(!isFullSize);
            }}
            data-testid="FileTab-FullSize-Toggle"
          />
        </Grid>
      </Grid>

      {isFullSize && (
        <Grid container sx={{ mb: 2 }}>
          <Grid item>
            <Typography>
              <WarningIcon color="warning" sx={{ mr: 1.5, mb: -0.5 }} fontSize="medium" />
              <FormattedMessage id="file.fullSizeWarningText" />
            </Typography>
          </Grid>
        </Grid>
      )}

      {files.length === 0 && (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <NoteAddIcon color="secondary" fontSize="large" />
          </Grid>
          <Grid item>
            <Typography color="secondary">
              <FormattedMessage id="file.noFilesSelected" />
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1}>
        {files.map((f, index) => (
          <FilePreview
            key={index}
            file={f}
            onDelete={() => {
              setRemoveId(f.id!);
              setOpenRemoveDialog(true);
            }}
            onEdit={() => {
              setCurrentPhoto(f);
              setOpenEditDialog(true);
            }}
            data-testid={`FilePreview-${index}`}
            disableEdit={f.isFullSize || false}
          />
        ))}
        {/* Extra space to scroll down */}
        <div style={{ height: "80px", width: "80px" }}> </div>
      </Grid>
      <input
        accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
        className={classes.input}
        id="upload-button-file"
        type="file"
        data-testid="FileTab-FileInput"
        onChange={handleFileInput}
      />
      <FullScreenDialog
        open={openEditDialog}
        setOpen={(open: boolean) => setOpenCloseConfirmation(!open)}
        title={`${externalId} / ${intl.formatMessage({
          id: "file.editImage",
        })}`}
        centerTitle
        goingBack
        child={
          <EditPhoto
            setOpenEditDialog={setOpenEditDialog}
            currentPhoto={currentPhoto}
            onSave={(file) => {
              dispatch(updateFile({ file }));
            }}
            openCloseConfirmation={openCloseConfirmation}
            setOpenCloseConfirmation={setOpenCloseConfirmation}
          />
        }
      />
      <PromptDialog
        aria-labelledby="form-dialog-title"
        open={openRemoveDialog}
        setOpen={setOpenRemoveDialog}
        okText={intl.formatMessage({ id: "general.confirm" })}
        onOk={() => dispatch(removeFile({ id: removeId! }))}
        onCancel={() => setOpenRemoveDialog(false)}
        title={<FormattedMessage id="file.remove" />}
        promptContent={<FormattedMessage id="visit.file.removePrompt" />}
      />
    </StyledContainer>
  );
};
