import { chooseContact, toDateString } from "helpers";
import { JobCategoryType } from "operations/schema/schema";
import { Job } from "./Job";
import { Times } from "./travelTimes";

export type JobVisitFormValues = {
  actionId1: string;
  actionId2?: string;
  actionId3?: string;
  changeServiceTerms: boolean;
  customerSignatureImageData: null | string;
  customerSignerName: string;
  engineerSignatureImageData: null | string;
  jobCategory?: JobCategoryType | null;
  serviceReportEmail: string | null;
  skipSignature: boolean;
  solutionDescription: string;
  workTimes: Times[];
};
export type JobVisitForm = {
  values: JobVisitFormValues;
  errors: any;
};

export const initialVisitFormValues = (
  job: Job,
  jobCategories: JobCategoryType[]
): JobVisitFormValues => {
  const contact = chooseContact(job.preferredContact, job.customer?.contacts);

  const action1 = job.actions?.find((x) => x?.sortId === "0");
  const action2 = job.actions?.find((x) => x?.sortId === "1");
  const action3 = job.actions?.find((x) => x?.sortId === "2");
  return {
    workTimes: job.workTimes.map((wt) => ({
      startDate: toDateString(wt.startTime)!,
      startTime: toDateString(wt.startTime)!,
      stopDate: toDateString(wt.stopTime) || undefined,
      stopTime: toDateString(wt.stopTime) || undefined,
    })),
    actionId1: action1?.id ?? "",
    actionId2: action2?.id ?? "",
    actionId3: action3?.id ?? "",
    solutionDescription: job.solutionDescription ?? "",
    customerSignerName: contact?.name ?? "",
    customerSignatureImageData: null,
    engineerSignatureImageData: null,
    skipSignature: false,
    serviceReportEmail: null,
    changeServiceTerms: false,
    jobCategory: jobCategories.find((x) => x.id === job.categoryId),
  };
};
