import { History as HistoryIcon } from "@mui/icons-material";
import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { DetailAccordion } from "components/DetailAccordion";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";
import { isFlagEnabled } from "store/slices/user.store";

const PREFIX = "JobDetailTab";

const classes = {
  historyIconAdjust: `${PREFIX}-eq-history-icon-adjust`,
  noPadding: `${PREFIX}-no-padding`,
  disabled: `${PREFIX}-disabled`,
};

interface CustomerDisplayProps {
  readonly?: boolean;
}

export const CustomerDisplay: FC<CustomerDisplayProps> = (props: CustomerDisplayProps) => {
  const { readonly } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const job = useAppSelector(selectPageSelectedJob);
  const customerServiceHistoryEnabled = useAppSelector((s) =>
    isFlagEnabled(s, "CustomerServiceHistory")
  );

  const backTo = pathname.split("/").filter((x) => x)[0];

  const openServiceHistory = (id: string) => {
    if (!readonly) {
      navigate("/history/site/" + id, {
        state: {
          navTitle: job.customer?.name,
          backTo: backTo,
        },
      });
    }
  };

  const historyIcon = (
    <HistoryIcon className={`${classes.historyIconAdjust} ${readonly ? classes.disabled : ""}`} />
  );

  return (
    <ListItem
      component="div"
      disableGutters
      className={classes.noPadding}
      data-testid="CustomerDisplay"
    >
      {customerServiceHistoryEnabled && job.customer?.id && (
        <ListItemIcon
          onClick={() => openServiceHistory(job.customer!.id)}
          className="min-width-38"
          sx={{ alignSelf: "flex-start", mt: 1 }}
          data-testid="CustomerDisplay-OpenCustomerHistory"
        >
          {historyIcon}
        </ListItemIcon>
      )}
      <ListItemText>
        <Typography
          variant="h6"
          sx={{
            fontSize: "1.2rem",
          }}
        >
          {job.customer?.name}
        </Typography>
        {!!job.customer?.customerNo && (
          <DetailAccordion
            content={
              <>
                {job.customer?.id && (
                  <Typography>
                    {`${intl.formatMessage({
                      id: "job.customer.customerNo",
                    })}: ${job.customer?.customerNo}`}
                  </Typography>
                )}
              </>
            }
          />
        )}
      </ListItemText>
    </ListItem>
  );
};
