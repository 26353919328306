import { LockClock } from "@mui/icons-material";
import {
  AppBar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import { intlFormatDateTimeWithWeekday } from "helpers";
import { useAppDispatch } from "store";
import { setAppBar } from "store/slices/menu.store";

import { StyledChips } from "components/StyledChips";
import { TabPanel } from "components/TabPanel";
import TabToolbarPlaceholder from "components/TabToolbarPlaceholder";
import { JobDetailsSkeleton } from "components/job/JobDetailsSkeleton";

const PREFIX = "LegacyJobDetailTab";

const classes = {
  symptomDescription: `${PREFIX}-symptomDescription`,
  bold: `${PREFIX}-bold`,
  rightIconAdjust: `${PREFIX}-right-icon-adjust`,
  leftIconAdjust: `${PREFIX}-left-icon-adjust`,
  historyIconAdjust: `${PREFIX}-eq-history-icon-adjust`,
  noPadding: `${PREFIX}-no-padding`,
  leftIndent: `${PREFIX}-left-indent`,
  disabled: `${PREFIX}-disabled`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.symptomDescription}`]: {
    color: theme.palette.text.primary,
  },

  [`& .${classes.bold}`]: {
    fontWeight: 700,
  },

  [`& .${classes.rightIconAdjust}`]: {
    position: "relative",
    top: "0.2em",
    left: "0.2em",
  },
  [`& .${classes.leftIconAdjust}`]: {
    position: "relative",
    top: "0.2em",
    marginRight: theme.spacing(1),
  },
  [`& .${classes.historyIconAdjust}`]: {
    color: theme.palette.info.main,
    position: "relative",
    bottom: "0.1rem",
    marginRight: "0.2rem",
  },
  [`& .${classes.noPadding}`]: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  [`& .${classes.leftIndent}`]: {
    marginLeft: "38px",
  },
  [`& .${classes.disabled}`]: {
    color: theme.palette.secondary.main,
  },
}));

export const LegacyHistoryDetailsPage: FC = () => {
  const [tabValue, setTabValue] = useState("details");
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { state }: any = useLocation();
  const svcHistory = state.svcHistory;

  useEffect(() => {
    dispatch(
      setAppBar({
        title: svcHistory?.externalId ?? "",
        goingBack: true,
      })
    );
  }, [dispatch, svcHistory]);

  if (svcHistory === undefined) {
    return <JobDetailsSkeleton />;
  }

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          mt: 7,
          backgroundColor: "secondary.main",
        }}
      >
        <Tabs
          scrollButtons
          value={tabValue}
          onChange={(_, newValue: string) => {
            setTabValue(newValue);
          }}
          variant="scrollable"
          textColor="inherit"
        >
          <Tab label={intl.formatMessage({ id: "general.details" })} value="details" />
        </Tabs>
      </AppBar>
      <TabToolbarPlaceholder />
      <TabPanel index="details" value={tabValue}>
        <StyledCard elevation={1}>
          <CardHeader
            sx={{
              fontSize: "1.2rem",
            }}
            title={
              <Typography
                variant="h6"
                sx={{
                  fontSize: "1.2rem",
                }}
              >
                <LockClock className={classes.leftIconAdjust} />
                {intlFormatDateTimeWithWeekday(intl, svcHistory?.completedDate)}
                <ListItem component="div" disableGutters className={classes.noPadding}>
                  <ListItemText>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "1.2rem",
                      }}
                    >
                      {svcHistory?.parts}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Typography>
            }
          />
          <CardContent style={{ paddingTop: 0 }}>
            <Grid container direction="column" spacing={2}>
              <Grid item></Grid>
              <Grid item>
                <StyledChips category={svcHistory.actionCodes} symptoms={svcHistory.symptoms} />
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
      </TabPanel>
    </div>
  );
};
