import { NoMatchPage } from "components/NoMatchPage";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { JobsPage } from "./JobsPage";
import { NewJobsPage } from "./NewJobsPage";
import JobDetails from "./jobDetails";

const Router: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<JobsPage />} />
      <Route path="/new" element={<NewJobsPage />} />
      <Route path="/schedule" element={<JobsPage calendarView />} />
      <Route path=":id/*" element={<JobDetails />} />
      <Route path="*" element={<NoMatchPage />} />
    </Routes>
  );
};
export default Router;
